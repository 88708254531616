// deps
import {
  HOTEL_RATING_TYPE_VALUE_BIODIVERSITE,
  HOTEL_RATING_TYPE_VALUE_CITY_BREAK,
  HOTEL_RATING_TYPE_VALUE_ECOGESTE,
  HOTEL_RATING_TYPE_VALUE_ECOGITE,
  HOTEL_RATING_TYPE_VALUE_EPI,
  HOTEL_RATING_TYPE_VALUE_GITE_PANDA,
  HOTEL_RATING_TYPE_VALUE_KEY,
  HOTEL_RATING_TYPE_VALUE_STAR,
} from "../../../constants/hotel/rating";
import {
  ROOM_CATEGORY_RANKING_VALUE_CITYBREAK,
  ROOM_CATEGORY_RANKING_VALUE_EPI,
} from "../../../constants/room/category";

/**
 * @typedef {object} HotelRating
 * @prop {keyof typeof import("../../../constants/hotel/rating").HOTEL_RATING_TYPES} type
 */

/**
 * @typedef {object} Realm
 * @prop {string} reference
 */

/**
 * Le classement/agrément doit s’afficher sur le site ?
 * @param {object} param0
 * @param {HotelRating} param0.hotelRating
 * @param {Realm} param0.realm
 * @param {keyof typeof import("../../../constants/room/category").ROOM_CATEGORY_RANKINGS} param0.roomRankingType
 */
export default function hotelRatingIsVisible({
  hotelRating,
  realm,
  roomRankingType,
}) {
  switch (hotelRating?.type) {
    case HOTEL_RATING_TYPE_VALUE_ECOGITE:
    case HOTEL_RATING_TYPE_VALUE_ECOGESTE:
    case HOTEL_RATING_TYPE_VALUE_GITE_PANDA:
    case HOTEL_RATING_TYPE_VALUE_BIODIVERSITE:
      return "GDF" === realm?.reference;
    case HOTEL_RATING_TYPE_VALUE_EPI:
      return (
        ["GDF", "GERSRES"].includes(realm?.reference) &&
        ROOM_CATEGORY_RANKING_VALUE_EPI === roomRankingType
      );
    case HOTEL_RATING_TYPE_VALUE_CITY_BREAK:
      return (
        "GDF" === realm?.reference &&
        ROOM_CATEGORY_RANKING_VALUE_CITYBREAK === roomRankingType
      );
    case HOTEL_RATING_TYPE_VALUE_KEY:
      return ["CLEV", "GERSRES"].includes(realm?.reference);
    case HOTEL_RATING_TYPE_VALUE_STAR:
      return "GERSRES" === realm?.reference;
    default:
      return false;
  }
}
