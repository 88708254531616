// libraries
import { get } from "@cloudspire/legacy-shared/src/libraries";

/**
 * Retourne vrai si les données publiques du token sont correctes.
 */
const userIsTokenDataValid = ({ tokenData }) => {
  if ("string" !== typeof get(tokenData, "userId")) {
    return false;
  }

  if ("string" !== typeof get(tokenData, "userRef")) {
    return false;
  }

  if ("Customer" !== get(tokenData, "userClass")) {
    return false;
  }

  const exp = parseInt(get(tokenData, "exp"));
  if ("number" !== typeof exp) {
    return false;
  } else {
    const now = Date.now() / 1000;

    if (exp - now < 0) {
      // Si la date de validité du token est dépassée.
      return false;
    }
  }

  return true;
};

export default userIsTokenDataValid;
