type RoomGetCurrentRoomInChannelParam0Type = {
  room: ReturnType<
    typeof import("../../normalizers/room/parseResource").default
  >;
  configuration: ReturnType<
    typeof import("../../normalizers/configuration/parseData").default
  >;
};

/**
 * Retourne l’objet RoomInChannel associé à l’hébergement et en prennant en compte la délégation.
 */
export default function roomGetCurrentRoomInChannel({
  room,
  configuration,
}: RoomGetCurrentRoomInChannelParam0Type) {
  let roomInChannel = null;

  if (!configuration?.channel?.website?.useBookingDelegation) {
    roomInChannel = (room?.channelList ?? []).find(
      (channel) => channel.channelId === configuration.channel.id
    );
  }

  return roomInChannel ?? room?.mainChannel;
}
