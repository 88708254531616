import { ROOM_STATE_VALUE_TRASHED } from "../../../constants/room";

type RoomStateIsTrashedParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'état de l'hébergement est supprimé.
 * @param {object} param0
 * @param param0.room
 * @return {boolean}
 */
const roomStateIsTrashed = ({ room }: RoomStateIsTrashedParam0Type) => {
  return ROOM_STATE_VALUE_TRASHED === room.state;
};

export default roomStateIsTrashed;
