import stringUppercaseFirst from "../string/uppercaseFirst";

/**
 * Normalise l’appel à `intl.formatMessage` pour les constantes.
 * @param {object} param0
 * @param {import("react-intl").IntlShape} param0.intl
 * @param {Record<string, Record<string, import("react-intl").MessageDescriptor>>} param0.messages
 * @param {string} param0.value
 * @param {string} [param0.fallback]
 * @param {Array<string>} param0.preferredAttributes Nom des attributs à utiliser dans l’ordre de préférence.
 * @param {object} [options]
 * @param {boolean} [options.uppercaseFirst] Met la première lettre en majuscule.
 * @returns {string}
 */
export default function intlFormatConstantByValue(param0, options = {}) {
  const {
    intl,
    messages,
    value,
    preferredAttributes,
    fallback = param0.value,
  } = param0;

  // On cherche la constante dans les traductions.
  const messageDescriptors = messages[value];

  if (messageDescriptors) {
    for (const attribute of preferredAttributes) {
      if (messageDescriptors[attribute]) {
        const { uppercaseFirst = false } = options;

        // Si on doit mettre la première lettre en majuscule.
        if (uppercaseFirst) {
          return stringUppercaseFirst(
            intl.formatMessage(messageDescriptors[attribute]),
          );
        }

        return intl.formatMessage(messageDescriptors[attribute]);
      }
    }
  }

  return fallback;
}
