// helpers
import { userHasEmail } from "../../user";

type RoomHasEmailOwnerInChargeOfBookingParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

const roomHasEmailOwnerInChargeOfBooking = ({
  room,
}: RoomHasEmailOwnerInChargeOfBookingParam0Type): boolean => {
  return (
    userHasEmail({ user: room.manager }) ||
    userHasEmail({ user: room.customer })
  );
};

export default roomHasEmailOwnerInChargeOfBooking;
