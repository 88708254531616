// libraries
import { get } from "@cloudspire/legacy-shared/src/libraries";

const parseUserData = ({ rawResponseData }) => {
  if (undefined === rawResponseData || null === rawResponseData) {
    return null;
  }

  return {
    firstname: get(rawResponseData, "firstname"),
    about: get(rawResponseData, "about"),
    lastname: get(rawResponseData, "lastname"),
    gender: get(rawResponseData, "gender"),
    locales: get(rawResponseData, "locales", []),
    hasEmail: get(
      rawResponseData,
      "hasEmail",
      Boolean(get(rawResponseData, "email"))
    ),
    bestPhone: get(rawResponseData, "bestPhone"),
    phone1: get(rawResponseData, "phone1"),
  };
};

export default parseUserData;
