import { ROOM_STATE_VALUE_REGISTERED } from "../../../constants/room";

type RoomStateIsRegisteredParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'état de l'hébergement est en attente de validation.
 * @param {object} param0
 * @param param0.room
 * @return {boolean}
 */
const roomStateIsRegistered = ({ room }: RoomStateIsRegisteredParam0Type) => {
  return ROOM_STATE_VALUE_REGISTERED === room.state;
};

export default roomStateIsRegistered;
