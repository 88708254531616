// Morphable

/** Événement associé sur une structure */
export const HOTEL_APPOINTMENT_OBJECT_TYPE_VALUE_HOTEL = "hotel";
/** Événement associé sur un projet de structure */
export const HOTEL_APPOINTMENT_OBJECT_TYPE_VALUE_HOTEL_PROJECT =
  "hotel_project";

export const HOTEL_APPOINTMENT_OBJECT_TYPES = {
  [HOTEL_APPOINTMENT_OBJECT_TYPE_VALUE_HOTEL]: {
    id: HOTEL_APPOINTMENT_OBJECT_TYPE_VALUE_HOTEL,
    label: "hébergement",
    labelWithPreposition: "de l’hébergement",
    labelWithPersonalPronoun: "l’hébergement",
  },
  [HOTEL_APPOINTMENT_OBJECT_TYPE_VALUE_HOTEL_PROJECT]: {
    id: HOTEL_APPOINTMENT_OBJECT_TYPE_VALUE_HOTEL_PROJECT,
    label: "projet d’hébergement",
    labelWithPreposition: "du projet d’hébergement",
    labelWithPersonalPronoun: "le projet d’hébergement",
  },
};

export const HOTEL_APPOINTMENT_OBJECT_TYPE_LIST = Object.values(
  HOTEL_APPOINTMENT_OBJECT_TYPES,
);

// Sous-type de classement

/** Sous-type de classement "Classement/agrément" */
export const HOTEL_APPOINTMENT_SUBTYPE_VALUE_RATING_VISIT = "rating_visit";
/** Sous-type de classement "Visite qualité" */
export const HOTEL_APPOINTMENT_SUBTYPE_VALUE_AUDIT_VISIT = "audit_visit";

export const HOTEL_APPOINTMENT_SUBTYPES = {
  [HOTEL_APPOINTMENT_SUBTYPE_VALUE_RATING_VISIT]: {
    id: HOTEL_APPOINTMENT_SUBTYPE_VALUE_RATING_VISIT,
    label: "classement/agrément",
  },
  [HOTEL_APPOINTMENT_SUBTYPE_VALUE_AUDIT_VISIT]: {
    id: HOTEL_APPOINTMENT_SUBTYPE_VALUE_AUDIT_VISIT,
    label: "visite qualité",
  },
};

export const HOTEL_APPOINTMENT_SUBTYPE_LIST = Object.values(
  HOTEL_APPOINTMENT_SUBTYPES,
);

// Type de rendez-vous

/** Événement de type "Rappel" */
export const HOTEL_APPOINTMENT_TYPE_VALUE_REMINDER = "reminder";
/** Événement de type "Rendez-vous" */
export const HOTEL_APPOINTMENT_TYPE_VALUE_APPOINTMENT = "appointment";
/** Événement de type "Classement meublés de tourisme" */
export const HOTEL_APPOINTMENT_TYPE_VALUE_STAR_RATING = "star_rating";
/** Événement de type "Classement Épis/Clés" */
export const HOTEL_APPOINTMENT_TYPE_VALUE_EPI_RATING = "epi_rating";

export const HOTEL_APPOINTMENT_TYPES = {
  [HOTEL_APPOINTMENT_TYPE_VALUE_REMINDER]: {
    id: HOTEL_APPOINTMENT_TYPE_VALUE_REMINDER,
    label: "rappel",
    emailLabel: "rappel",
    color: "#ff8000",
    subtypes: [],
  },
  [HOTEL_APPOINTMENT_TYPE_VALUE_APPOINTMENT]: {
    id: HOTEL_APPOINTMENT_TYPE_VALUE_APPOINTMENT,
    label: "rendez-vous",
    emailLabel: "rendez-vous",
    color: "#50288c",
    subtypes: [],
  },
  [HOTEL_APPOINTMENT_TYPE_VALUE_STAR_RATING]: {
    id: HOTEL_APPOINTMENT_TYPE_VALUE_STAR_RATING,
    label: "classement Meublé de tourisme",
    raterLabel: "meublés de tourisme",
    emailLabel: "rendez-vous pour un classement Meublé de tourisme",
    raterSymbolLabel: "étoiles",
    color: "#23b7e5",
    subtypes: [],
  },
  [HOTEL_APPOINTMENT_TYPE_VALUE_EPI_RATING]: {
    id: HOTEL_APPOINTMENT_TYPE_VALUE_EPI_RATING,
    label: "agrément épis Gîtes de France®",
    label2: "Gîtes de France®",
    raterLabel: "Gîtes de France®",
    emailLabel: "rendez-vous pour un agrément Gîtes de France®",
    raterSymbolLabel: "épis",
    color: "#159ab8",
    subtypes: [
      HOTEL_APPOINTMENT_SUBTYPES[HOTEL_APPOINTMENT_SUBTYPE_VALUE_RATING_VISIT],
      HOTEL_APPOINTMENT_SUBTYPES[HOTEL_APPOINTMENT_SUBTYPE_VALUE_AUDIT_VISIT],
    ],
  },
};

export const HOTEL_APPOINTMENT_TYPE_LIST = Object.values(
  HOTEL_APPOINTMENT_TYPES,
);

export const HOTEL_APPOINTMENT_TYPES_DEFINITIONS = {
  configuration: {
    [HOTEL_APPOINTMENT_TYPE_VALUE_APPOINTMENT]:
      HOTEL_APPOINTMENT_TYPES[HOTEL_APPOINTMENT_TYPE_VALUE_APPOINTMENT],
    [HOTEL_APPOINTMENT_TYPE_VALUE_STAR_RATING]:
      HOTEL_APPOINTMENT_TYPES[HOTEL_APPOINTMENT_TYPE_VALUE_STAR_RATING],
    [HOTEL_APPOINTMENT_TYPE_VALUE_EPI_RATING]:
      HOTEL_APPOINTMENT_TYPES[HOTEL_APPOINTMENT_TYPE_VALUE_EPI_RATING],
  },
};

// Lieu du rendez-vous

/** En ligne (visioconférence) */
export const HOTEL_APPOINTMENT_PLACE_VALUE_ONLINE = "online";
/** En agence */
export const HOTEL_APPOINTMENT_PLACE_VALUE_AGENCY = "agency";
/** À la structure */
export const HOTEL_APPOINTMENT_PLACE_VALUE_HOTEL = "hotel";
/** Chez le client */
export const HOTEL_APPOINTMENT_PLACE_VALUE_CUSTOMER = "customer";
/** Par téléphone */
export const HOTEL_APPOINTMENT_PLACE_VALUE_PHONE = "phone";
/** Autre */
export const HOTEL_APPOINTMENT_PLACE_VALUE_OTHER = "other";

export const HOTEL_APPOINTMENT_PLACES = {
  [HOTEL_APPOINTMENT_PLACE_VALUE_ONLINE]: {
    id: HOTEL_APPOINTMENT_PLACE_VALUE_ONLINE,
    label: "visio-conférence",
  },
  [HOTEL_APPOINTMENT_PLACE_VALUE_AGENCY]: {
    id: HOTEL_APPOINTMENT_PLACE_VALUE_AGENCY,
    label: "en agence",
  },
  [HOTEL_APPOINTMENT_PLACE_VALUE_HOTEL]: {
    id: HOTEL_APPOINTMENT_PLACE_VALUE_HOTEL,
    label: "à l’hébergement",
  },
  [HOTEL_APPOINTMENT_PLACE_VALUE_CUSTOMER]: {
    id: HOTEL_APPOINTMENT_PLACE_VALUE_CUSTOMER,
    label: "à votre domicile",
  },
  [HOTEL_APPOINTMENT_PLACE_VALUE_PHONE]: {
    id: HOTEL_APPOINTMENT_PLACE_VALUE_PHONE,
    label: "par téléphone",
  },
  [HOTEL_APPOINTMENT_PLACE_VALUE_OTHER]: {
    id: HOTEL_APPOINTMENT_PLACE_VALUE_OTHER,
    label: "autre",
  },
};

export const HOTEL_APPOINTMENT_PLACE_LIST = Object.values(
  HOTEL_APPOINTMENT_PLACES,
);

// État du rendez-vous

/** Événement en attente de confirmation */
export const HOTEL_APPOINTMENT_STATE_VALUE_PENDING = "pending";
/** Événement confirmé */
export const HOTEL_APPOINTMENT_STATE_VALUE_CONFIRMED = "confirmed";
/** Événement annulé */
export const HOTEL_APPOINTMENT_STATE_VALUE_CANCELED = "canceled";

export const HOTEL_APPOINTMENT_STATES = {
  [HOTEL_APPOINTMENT_STATE_VALUE_PENDING]: {
    label: "en attente de confirmation",
    id: HOTEL_APPOINTMENT_STATE_VALUE_PENDING,
    glyph: "[?]",
  },
  [HOTEL_APPOINTMENT_STATE_VALUE_CONFIRMED]: {
    label: "confirmé",
    id: HOTEL_APPOINTMENT_STATE_VALUE_CONFIRMED,
    glyph: "[✓]",
  },
  [HOTEL_APPOINTMENT_STATE_VALUE_CANCELED]: {
    label: "annulé",
    id: HOTEL_APPOINTMENT_STATE_VALUE_CANCELED,
    glyph: "[⨯]",
  },
};

export const HOTEL_APPOINTMENT_STATE_LIST = Object.values(
  HOTEL_APPOINTMENT_STATES,
);

// Visibilité

/** Événement partagé avec le propriétaire */
export const HOTEL_APPOINTMENT_VISIBILITY_VALUE_SHARED = "shared";
/** Événement privé */
export const HOTEL_APPOINTMENT_VISIBILITY_VALUE_PRIVATE = "private";

export const HOTEL_APPOINTMENT_VISIBILITIES = {
  [HOTEL_APPOINTMENT_VISIBILITY_VALUE_SHARED]: {
    id: HOTEL_APPOINTMENT_VISIBILITY_VALUE_SHARED,
    label: "partagé avec le propriétaire",
    description: "visible sur Cloudspire et par le propriétaire",
  },
  [HOTEL_APPOINTMENT_VISIBILITY_VALUE_PRIVATE]: {
    id: HOTEL_APPOINTMENT_VISIBILITY_VALUE_PRIVATE,
    label: "privé",
    description: "visible uniquement sur Cloudspire",
  },
};

export const HOTEL_APPOINTMENT_VISIBILITY_LIST = Object.values(
  HOTEL_APPOINTMENT_VISIBILITIES,
);
