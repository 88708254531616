export { default as roomAddRoomToLastSeen } from "./addRoomToLastSeen";
export { default as roomCompressForLastSeen } from "./compressForLastSeen";
export { default as roomExtractReferenceFromSlug } from "./extractReferenceFromSlug";
export { default as roomFindBestRoomUsingCapacity } from "./findBestRoomUsingCapacity";
export { default as roomGenerateBreacrumbItemList } from "./generateBreacrumbItemList";
export { default as roomGetBestColor } from "./getBestColor";
export { default as roomHasEmailOwnerInChargeOfBooking } from "./hasEmailOwnerInChargeOfBooking";
export { default as roomHasChildren } from "./hasChildren";
export { default as roomHasHandicaps } from "./hasHandicaps";
export { default as roomHasLastSeenRoom } from "./hasLastSeenRoom";
export { default as roomHasMap } from "./hasMap";
export { default as roomDisplayMap } from "./displayMap";
export { default as roomHasMovies } from "./hasMovies";
export { default as roomHasPictures } from "./hasPictures";
export { default as roomHasPieces } from "./hasPieces";
export { default as roomHasOptions } from "./hasOptions";
export { default as roomHasParent } from "./hasParent";
export { default as roomIsPricingAvailable } from "./isPricingAvailable";
export { default as roomHasRanking } from "./hasRanking";
export { default as roomInChannel } from "./inChannel";
export { default as roomHasReviews } from "./hasReviews";
export { default as roomIsBookableOnline } from "./isBookableOnline";
export { default as roomIsAllInclusiveRate } from "./isAllInclusiveRate";
export { default as roomIsNatureChild } from "./isNatureChild";
export { default as roomIsNatureParent } from "./isNatureParent";
export { default as roomIsValidReference } from "./isValidReference";
export { default as roomMapRankingCitybreakToEpi } from "./mapRankingCitybreakToEpi";
export { default as roomMayContactOwner } from "./mayContactOwner";
export { default as roomIsStateDisabled } from "./isStateDisabled";
export { default as roomIsStateEnabled } from "./isStateEnabled";
export { default as roomIsStateRegistered } from "./isStateRegistered";
export { default as roomIsStateTrashed } from "./isStateTrashed";
export { default as roomIsRankingCityBreak } from "./isRankingCityBreak";
export { default as roomIsRankingEpi } from "./isRankingEpi";
export { default as roomIsRankingVisible } from "./isRankingVisible";
export { default as roomSetLastSeenRoomList } from "./setLastSeenRoomList";
export { default as roomIsRankingValid } from "./isRankingValid";
