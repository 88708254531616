import roomCompressForLastSeen from "../compressForLastSeen";
import roomSetLastSeenRoomList from "../setLastSeenRoomList";

type RoomAddRoomToLastSeenParam0Type = {
  room: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/room").roomParseData
  >;
};

/**
 * Ajoute un hébergement à la liste des hébergements récents.
 */
const roomAddRoomToLastSeen = ({
  room,
}: RoomAddRoomToLastSeenParam0Type): void => {
  // Récupère les hébergements sotckés dans le local-storage.
  const rawRoomList = localStorage.getItem("lastSeenRoomList");

  // Parse les hébergements.
  const storageLastSeenRoomList = rawRoomList ? JSON.parse(rawRoomList) : [];

  if (
    storageLastSeenRoomList.every(
      (lastSeenRoom) => lastSeenRoom.reference !== room.reference
    )
  ) {
    // Ajoute l'hébergement de le tableau en le normalisant.
    storageLastSeenRoomList.push(roomCompressForLastSeen({ room }));

    // Restreint le nombre de d'hébergement stocké à 6 (en supprimant les plus anciens).
    storageLastSeenRoomList.splice(0, storageLastSeenRoomList.length - 6);

    roomSetLastSeenRoomList({ roomList: storageLastSeenRoomList });
  }
};

export default roomAddRoomToLastSeen;
