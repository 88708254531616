// libraries
import { get } from "@cloudspire/legacy-shared/src/libraries";

/**
 * Parse la réponse API d'un hébergement.
 */
const roomGetIndexesParseResponse = ({ rawResponse }) => {
  return {
    data: get(rawResponse, "data", []).map((rawRoomSlug) => rawRoomSlug),
    meta: {
      total: get(rawResponse, "meta.nb_results", 0),
    },
  };
};

export default roomGetIndexesParseResponse;
