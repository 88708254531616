// libraries
import { get } from "@cloudspire/legacy-shared/src/libraries";

// normalizers
import roomParseData from "./parseData";

/**
 * Parse la réponse API d'un hébergement.
 */
const roomParseResponse = ({ rawResponse, features, featureIdListByType }) => {
  return {
    data: roomParseData({
      rawResponseData: get(rawResponse, "data"),
      features,
      featureIdListByType,
    }),
  };
};

export default roomParseResponse;
