// constants
import { HOTEL_CATEGORY_INTL_MESSAGES } from "@cloudspire/shared/constants/hotel";

// libraries
import { generateUri } from "@cloudspire/legacy-shared/src/libraries";
import stringUppercaseFirst from "@cloudspire/shared/utils/string/uppercaseFirst";

// helpers
import { contentGetSlugFromCategoryAlias } from "@cloudspire/legacy-resources/src/helpers/content";

/**
 * Génère les différents liens du fil d'Ariane.
 */
const roomGenerateBreacrumbItemList = ({
  room,
  intl,
  route,
  configuration,
  router,
}) => {
  if (room) {
    return [
      {
        label: intl.formatMessage({
          defaultMessage: "Accueil",
        }),
        to: generateUri({
          router,
          name: "App.Action.Home",
          parameters: {
            lang: route.getParameter("lang"),
          },
        }),
      },
      ...(room.mainCategory
        ? [
            {
              label: HOTEL_CATEGORY_INTL_MESSAGES.byValue?.[room.mainCategory]
                ?.label
                ? stringUppercaseFirst(
                    intl.formatMessage(
                      HOTEL_CATEGORY_INTL_MESSAGES.byValue[room.mainCategory]
                        .label
                    )
                  )
                : room.mainCategory,
              to: generateUri({
                router,
                name: "App.Action.RoomListWithSlug",
                parameters: {
                  lang: route.getParameter("lang"),
                  slug: contentGetSlugFromCategoryAlias({
                    contentList: configuration.channel.website.contents.list,
                    categoryAlias: room?.mainCategory,
                  }),
                },
              }),
            },
          ]
        : []),
      {
        label: room.city,
        to: generateUri({
          router,
          name: "App.Action.RoomList",
          parameters: {
            lang: route.getParameter("lang"),
          },
          query: {
            city: room.city,
            lat: room.lat,
            lng: room.lng,
          },
        }),
      },
      {
        label: room.name,
        to: generateUri({
          router,
          name: "App.Action.RoomWithSlug",
          parameters: {
            lang: route.getParameter("lang"),
            slug: room.slug,
          },
        }),
      },
    ];
  }

  return [];
};

export default roomGenerateBreacrumbItemList;
