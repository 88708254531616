// libraries
import { get } from "@cloudspire/legacy-shared/src/libraries";

// normalizers
import roomParseData from "./parseData";

/**
 * Parse la réponse API d'un hébergement.
 */
const roomSearchParseResponse = ({
  rawResponse,
  featureIdListByType,
  features,
}) => {
  return {
    data: get(rawResponse, "data", []).map((rawRoom) =>
      roomParseData({
        rawResponseData: rawRoom,
        featureIdListByType,
        features,
      })
    ),
    meta: {
      total: get(rawResponse, "meta.nb_results", 0),
    },
  };
};

export default roomSearchParseResponse;
