// deps
import { defineMessage } from "react-intl";

// constants
import {
  HOTEL_APPOINTMENT_TYPE_VALUE_EPI_RATING,
  HOTEL_APPOINTMENT_TYPE_VALUE_STAR_RATING,
} from "./appointment";

// Morphable

/** Événement associé sur une structure */
export const HOTEL_RATING_OBJECT_TYPE_VALUE_HOTEL = "hotel";
/** Événement associé sur un projet de structure */
export const HOTEL_RATING_OBJECT_TYPE_VALUE_HOTEL_PROJECT = "hotel_project";

export const HOTEL_RATING_OBJECT_TYPES = {
  [HOTEL_RATING_OBJECT_TYPE_VALUE_HOTEL]: {
    id: HOTEL_RATING_OBJECT_TYPE_VALUE_HOTEL,
    label: "hébergement",
  },
  [HOTEL_RATING_OBJECT_TYPE_VALUE_HOTEL_PROJECT]: {
    id: HOTEL_RATING_OBJECT_TYPE_VALUE_HOTEL_PROJECT,
    label: "projet d’hébergement",
  },
};

export const HOTEL_RATING_OBJECT_TYPE_LIST = Object.values(
  HOTEL_RATING_OBJECT_TYPES,
);

// Source

/** Classement réalisé via Cloudspire */
export const HOTEL_RATING_SOURCE_VALUE_SYSTEM = "system";
/** Classement réalisé en externe */
export const HOTEL_RATING_SOURCE_VALUE_EXT = "ext";

export const HOTEL_RATING_SOURCES = {
  [HOTEL_RATING_SOURCE_VALUE_SYSTEM]: {
    id: HOTEL_RATING_SOURCE_VALUE_SYSTEM,
    label: "Cloudspire",
  },
  [HOTEL_RATING_SOURCE_VALUE_EXT]: {
    id: HOTEL_RATING_SOURCE_VALUE_EXT,
    label: "externe",
  },
};

export const HOTEL_RATING_SOURCE_LIST = Object.values(HOTEL_RATING_SOURCES);

// Classement meublés de tourisme

/** 1 étoile */
export const HOTEL_RATING_STAR_VALUE_1 = "star_1";
/** 2 étoiles */
export const HOTEL_RATING_STAR_VALUE_2 = "star_2";
/** 3 étoiles */
export const HOTEL_RATING_STAR_VALUE_3 = "star_3";
/** 4 étoiles */
export const HOTEL_RATING_STAR_VALUE_4 = "star_4";
/** 5 étoiles */
export const HOTEL_RATING_STAR_VALUE_5 = "star_5";

export const HOTEL_RATING_STARS = {
  [HOTEL_RATING_STAR_VALUE_1]: {
    id: HOTEL_RATING_STAR_VALUE_1,
    label: "1 étoile",
    value: 1,
    logoPath: "/assets/logos/meuble-de-tourisme/1-etoile.png",
    evaluation: {
      aLaCartePercentageRequired: 0.05,
      surfaceSettings: {
        baseSurface: 12,
        tolerance: 0,
        additionalSurfacePerAdditionalBedroom: 7,
        additionalSurfacePerAdditionalPerson: 3,
        maxPersonPerBedroom: 4,
      },
    },
  },
  [HOTEL_RATING_STAR_VALUE_2]: {
    id: HOTEL_RATING_STAR_VALUE_2,
    label: "2 étoiles",
    value: 2,
    logoPath: "/assets/logos/meuble-de-tourisme/2-etoiles.png",
    evaluation: {
      aLaCartePercentageRequired: 0.1,
      surfaceSettings: {
        baseSurface: 14,
        tolerance: 0,
        additionalSurfacePerAdditionalBedroom: 8,
        additionalSurfacePerAdditionalPerson: 3,
        maxPersonPerBedroom: 4,
      },
    },
  },
  [HOTEL_RATING_STAR_VALUE_3]: {
    id: HOTEL_RATING_STAR_VALUE_3,
    label: "3 étoiles",
    value: 3,
    logoPath: "/assets/logos/meuble-de-tourisme/3-etoiles.png",
    evaluation: {
      aLaCartePercentageRequired: 0.2,
      surfaceSettings: {
        baseSurface: 18,
        tolerance: 0.1,
        additionalSurfacePerAdditionalBedroom: 9,
        additionalSurfacePerAdditionalPerson: 3,
        maxPersonPerBedroom: 4,
      },
    },
  },
  [HOTEL_RATING_STAR_VALUE_4]: {
    id: HOTEL_RATING_STAR_VALUE_4,
    label: "4 étoiles",
    value: 4,
    logoPath: "/assets/logos/meuble-de-tourisme/4-etoiles.png",
    evaluation: {
      aLaCartePercentageRequired: 0.3,
      surfaceSettings: {
        baseSurface: 20,
        tolerance: 0.1,
        additionalSurfacePerAdditionalBedroom: 10,
        additionalSurfacePerAdditionalPerson: 3,
        maxPersonPerBedroom: 4,
      },
    },
  },
  [HOTEL_RATING_STAR_VALUE_5]: {
    id: HOTEL_RATING_STAR_VALUE_5,
    label: "5 étoiles",
    value: 5,
    logoPath: "/assets/logos/meuble-de-tourisme/5-etoiles.png",
    evaluation: {
      aLaCartePercentageRequired: 0.4,
      surfaceSettings: {
        baseSurface: 26,
        tolerance: 0.1,
        additionalSurfacePerAdditionalBedroom: 12,
        additionalSurfacePerAdditionalPerson: 3,
        maxPersonPerBedroom: 3,
      },
    },
  },
};

export const HOTEL_RATING_STARS_BY_VALUE = {
  1: HOTEL_RATING_STARS[HOTEL_RATING_STAR_VALUE_1],
  2: HOTEL_RATING_STARS[HOTEL_RATING_STAR_VALUE_2],
  3: HOTEL_RATING_STARS[HOTEL_RATING_STAR_VALUE_3],
  4: HOTEL_RATING_STARS[HOTEL_RATING_STAR_VALUE_4],
  5: HOTEL_RATING_STARS[HOTEL_RATING_STAR_VALUE_5],
};

export const HOTEL_RATING_STAR_LIST = Object.values(HOTEL_RATING_STARS);

// Classement Gîtes de France

/** 1 épi  */
export const HOTEL_RATING_EPI_VALUE_1 = "epi_1";
/** 2 épis  */
export const HOTEL_RATING_EPI_VALUE_2 = "epi_2";
/** 3 épis  */
export const HOTEL_RATING_EPI_VALUE_3 = "epi_3";
/** 4 épis  */
export const HOTEL_RATING_EPI_VALUE_4 = "epi_4";
/** 5 épis  */
export const HOTEL_RATING_EPI_VALUE_5 = "epi_5";

export const HOTEL_RATING_EPIS = {
  [HOTEL_RATING_EPI_VALUE_1]: {
    id: HOTEL_RATING_EPI_VALUE_1,
    label: "1 épi",
    value: 1,
  },
  [HOTEL_RATING_EPI_VALUE_2]: {
    id: HOTEL_RATING_EPI_VALUE_2,
    label: "2 épis",
    value: 2,
  },
  [HOTEL_RATING_EPI_VALUE_3]: {
    id: HOTEL_RATING_EPI_VALUE_3,
    label: "3 épis",
    value: 3,
  },
  [HOTEL_RATING_EPI_VALUE_4]: {
    id: HOTEL_RATING_EPI_VALUE_4,
    label: "4 épis",
    value: 4,
  },
  [HOTEL_RATING_EPI_VALUE_5]: {
    id: HOTEL_RATING_EPI_VALUE_5,
    label: "5 épis",
    value: 5,
  },
};

export const HOTEL_RATING_EPI_INTL_MESSAGES = {
  byValue: {
    [HOTEL_RATING_EPI_VALUE_1]: {
      label: defineMessage({
        defaultMessage: "1 épi",
        description: "Valeur d’agrément épis Gîtes de France",
      }),
    },
    [HOTEL_RATING_EPI_VALUE_2]: {
      label: defineMessage({
        defaultMessage: "2 épis",
        description: "Valeur d’agrément épis Gîtes de France",
      }),
    },
    [HOTEL_RATING_EPI_VALUE_3]: {
      label: defineMessage({
        defaultMessage: "3 épis",
        description: "Valeur d’agrément épis Gîtes de France",
      }),
    },
    [HOTEL_RATING_EPI_VALUE_4]: {
      label: defineMessage({
        defaultMessage: "4 épis",
        description: "Valeur d’agrément épis Gîtes de France",
      }),
    },
    [HOTEL_RATING_EPI_VALUE_5]: {
      label: defineMessage({
        defaultMessage: "5 épis",
        description: "Valeur d’agrément épis Gîtes de France",
      }),
    },
  },
};

export const HOTEL_RATING_EPI_LIST = Object.values(HOTEL_RATING_EPIS);

export const HOTEL_RATING_VIRTUAL_EPI_VALUE_NA = "n/a";

// Classement citybreak

/** Confort */
export const HOTEL_RATING_CITY_BREAK_VALUE_CONFORT = "city_break_comfort";
/** Premium */
export const HOTEL_RATING_CITY_BREAK_VALUE_PREMIUM = "city_break_premium";
/** Luxury */
export const HOTEL_RATING_CITY_BREAK_VALUE_LUXURY = "city_break_luxury";

export const HOTEL_RATING_CITY_BREAKS = {
  [HOTEL_RATING_CITY_BREAK_VALUE_CONFORT]: {
    id: HOTEL_RATING_CITY_BREAK_VALUE_CONFORT,
    label: "confort",
    epiValue: 3,
  },
  [HOTEL_RATING_CITY_BREAK_VALUE_PREMIUM]: {
    id: HOTEL_RATING_CITY_BREAK_VALUE_PREMIUM,
    label: "premium",
    epiValue: 4,
  },
  [HOTEL_RATING_CITY_BREAK_VALUE_LUXURY]: {
    id: HOTEL_RATING_CITY_BREAK_VALUE_LUXURY,
    label: "luxury",
    epiValue: 5,
  },
};

export const HOTEL_RATING_CITY_BREAK_INTL_MESSAGES = {
  byValue: {
    [HOTEL_RATING_CITY_BREAK_VALUE_CONFORT]: {
      label: defineMessage({
        defaultMessage: "confort",
        description: "Valeur d’agrément city break Gîtes de France",
      }),
    },
    [HOTEL_RATING_CITY_BREAK_VALUE_PREMIUM]: {
      label: defineMessage({
        defaultMessage: "premium",
        description: "Valeur d’agrément city break Gîtes de France",
      }),
    },
    [HOTEL_RATING_CITY_BREAK_VALUE_LUXURY]: {
      label: defineMessage({
        defaultMessage: "luxury",
        description: "Valeur d’agrément city break Gîtes de France",
      }),
    },
  },
};

export const HOTEL_RATING_CITY_BREAK_LIST = Object.values(
  HOTEL_RATING_CITY_BREAKS,
);

// Classement Clés Clévacances

/** 1 clé */
export const HOTEL_RATING_KEY_VALUE_1 = "key_1";
/** 2 clés */
export const HOTEL_RATING_KEY_VALUE_2 = "key_2";
/** 3 clés */
export const HOTEL_RATING_KEY_VALUE_3 = "key_3";
/** 4 clés */
export const HOTEL_RATING_KEY_VALUE_4 = "key_4";
/** 5 clés */
export const HOTEL_RATING_KEY_VALUE_5 = "key_5";

export const HOTEL_RATING_KEYS = {
  [HOTEL_RATING_KEY_VALUE_1]: {
    id: HOTEL_RATING_KEY_VALUE_1,
    label: "1 clé",
    value: 1,
  },
  [HOTEL_RATING_KEY_VALUE_2]: {
    id: HOTEL_RATING_KEY_VALUE_2,
    label: "2 clés",
    value: 2,
  },
  [HOTEL_RATING_KEY_VALUE_3]: {
    id: HOTEL_RATING_KEY_VALUE_3,
    label: "3 clés",
    value: 3,
  },
  [HOTEL_RATING_KEY_VALUE_4]: {
    id: HOTEL_RATING_KEY_VALUE_4,
    label: "4 clés",
    value: 4,
  },
  [HOTEL_RATING_KEY_VALUE_5]: {
    id: HOTEL_RATING_KEY_VALUE_5,
    label: "5 clés",
    value: 5,
  },
};

export const HOTEL_RATING_KEY_INTL_MESSAGES = {
  byValue: {
    [HOTEL_RATING_KEY_VALUE_1]: {
      label: defineMessage({
        defaultMessage: "1 clé",
        description: "Valeur d’agrément Clévacances",
      }),
    },
    [HOTEL_RATING_KEY_VALUE_2]: {
      label: defineMessage({
        defaultMessage: "2 clés",
        description: "Valeur d’agrément Clévacances",
      }),
    },
    [HOTEL_RATING_KEY_VALUE_3]: {
      label: defineMessage({
        defaultMessage: "3 clés",
        description: "Valeur d’agrément Clévacances",
      }),
    },
    [HOTEL_RATING_KEY_VALUE_4]: {
      label: defineMessage({
        defaultMessage: "4 clés",
        description: "Valeur d’agrément Clévacances",
      }),
    },
    [HOTEL_RATING_KEY_VALUE_5]: {
      label: defineMessage({
        defaultMessage: "5 clés",
        description: "Valeur d’agrément Clévacances",
      }),
    },
  },
};

export const HOTEL_RATING_KEY_LIST = Object.values(HOTEL_RATING_KEYS);

// Type

/** Classement "Meublé de tourisme" */
export const HOTEL_RATING_TYPE_VALUE_STAR = "star";
/** Agrément de "Épis Gîtes de France" */
export const HOTEL_RATING_TYPE_VALUE_EPI = "epi";
/** Agrément de "City break" */
export const HOTEL_RATING_TYPE_VALUE_CITY_BREAK = "city_break";
/** Classement Clés Clévacances */
export const HOTEL_RATING_TYPE_VALUE_KEY = "key";
/** Classement insolite */
export const HOTEL_RATING_TYPE_VALUE_INSOLITE = "insolite";
/** Classement camping */
export const HOTEL_RATING_TYPE_VALUE_CAMPING = "camping";
/** Classement parc résidentiel de loisir */
export const HOTEL_RATING_TYPE_VALUE_PARC_RESIDENTIEL_LOISIR =
  "parc_res_loisir";
/** Agrément écogîte */
export const HOTEL_RATING_TYPE_VALUE_ECOGITE = "ecogite";
/** Agrément écogeste */
export const HOTEL_RATING_TYPE_VALUE_ECOGESTE = "ecogeste";
/** Agrément biodiversité */
export const HOTEL_RATING_TYPE_VALUE_BIODIVERSITE = "biodiversite";
/** Agrément Gîte penda */
export const HOTEL_RATING_TYPE_VALUE_GITE_PANDA = "gite_panda";

export const HOTEL_RATING_TYPES = {
  [HOTEL_RATING_TYPE_VALUE_STAR]: {
    id: HOTEL_RATING_TYPE_VALUE_STAR,
    label: "classement meublés de tourisme",
    emailLabel: "classement Meublé de tourisme",
    values: HOTEL_RATING_STAR_LIST,
    weight: 1,
  },
  [HOTEL_RATING_TYPE_VALUE_EPI]: {
    id: HOTEL_RATING_TYPE_VALUE_EPI,
    label: "agrément épis Gîtes de France®",
    emailLabel: "agrément Gîtes de France®",
    values: HOTEL_RATING_EPI_LIST,
    weight: 3,
  },
  [HOTEL_RATING_TYPE_VALUE_CITY_BREAK]: {
    id: HOTEL_RATING_TYPE_VALUE_CITY_BREAK,
    label: "agrément city break Gîtes de France",
    emailLabel: "agrément Gîtes de France®",
    values: HOTEL_RATING_CITY_BREAK_LIST,
    weight: 2,
  },
  [HOTEL_RATING_TYPE_VALUE_KEY]: {
    id: HOTEL_RATING_TYPE_VALUE_KEY,
    label: "agrément clés Clévacances",
    emailLabel: "agrément clés Clévacances",
    values: HOTEL_RATING_KEY_LIST,
    weight: 2,
  },
  [HOTEL_RATING_TYPE_VALUE_INSOLITE]: {
    id: HOTEL_RATING_TYPE_VALUE_INSOLITE,
    label: "classement insolite",
    emailLabel: "classement insolite",
    values: null,
    weight: 0,
  },
  [HOTEL_RATING_TYPE_VALUE_CAMPING]: {
    id: HOTEL_RATING_TYPE_VALUE_CAMPING,
    label: "classement camping",
    emailLabel: "classement camping",
    values: null,
    weight: 0,
  },
  [HOTEL_RATING_TYPE_VALUE_PARC_RESIDENTIEL_LOISIR]: {
    id: HOTEL_RATING_TYPE_VALUE_PARC_RESIDENTIEL_LOISIR,
    label: "classement parc résidentiel de loisir",
    emailLabel: "classement parc résidentiel de loisir",
    values: null,
    weight: 0,
  },
  [HOTEL_RATING_TYPE_VALUE_ECOGITE]: {
    id: HOTEL_RATING_TYPE_VALUE_ECOGITE,
    label: "agrément écogîte",
    emailLabel: "agrément écogîte",
    values: null,
    weight: 0,
    logoPath: "/assets/logos/ecogite.svg",
  },
  [HOTEL_RATING_TYPE_VALUE_ECOGESTE]: {
    id: HOTEL_RATING_TYPE_VALUE_ECOGESTE,
    label: "agrément écogeste",
    emailLabel: "agrément écogeste",
    values: null,
    weight: 0,
    logoPath: "/assets/logos/ecogeste.svg",
  },
  [HOTEL_RATING_TYPE_VALUE_BIODIVERSITE]: {
    id: HOTEL_RATING_TYPE_VALUE_BIODIVERSITE,
    label: "agrément biodiversité",
    emailLabel: "agrément biodiversité",
    values: null,
    weight: 0,
    logoPath: "/assets/logos/biodiversite.svg",
  },
  [HOTEL_RATING_TYPE_VALUE_GITE_PANDA]: {
    id: HOTEL_RATING_TYPE_VALUE_GITE_PANDA,
    label: "agrément Gîte panda",
    emailLabel: "agrément Gîte panda",
    values: null,
    weight: 0,
    logoPath: "/assets/logos/gite-panda.svg",
  },
};

export const HOTEL_RATING_TYPE_LIST = Object.values(HOTEL_RATING_TYPES);

export const HOTEL_RATING_TYPE_HOTEL_APPOINTMENT_MAP_DEFINITIONS = {
  [HOTEL_APPOINTMENT_TYPE_VALUE_STAR_RATING]:
    HOTEL_RATING_TYPES[HOTEL_RATING_TYPE_VALUE_STAR],
  [HOTEL_APPOINTMENT_TYPE_VALUE_EPI_RATING]:
    HOTEL_RATING_TYPES[HOTEL_RATING_TYPE_VALUE_EPI],
};

// Classement libre (virtuel)

export const HOTEL_RATING_VIRTUAL_ANY_VALUE_FREE = "free";
export const HOTEL_RATING_VIRTUAL_ANY_VALUE_NONE = "none";

// Statut du classement

/** Attente de traitement */
export const HOTEL_RATING_STATUS_VALUE_PENDING = "pending";
/** Terminé */
export const HOTEL_RATING_STATUS_VALUE_CONFIRMED = "confirmed";
/** Refusé */
export const HOTEL_RATING_STATUS_VALUE_REFUSED = "refused";

export const HOTEL_RATING_STATUSES = {
  [HOTEL_RATING_STATUS_VALUE_PENDING]: {
    id: HOTEL_RATING_STATUS_VALUE_PENDING,
    label: "en attente",
    label2: "en attente",
    raterLabel: "brouillon",
    color: "#23b7e5",
    chakraColor: "blue",
    io5Icon: "IoHourglass",
  },
  [HOTEL_RATING_STATUS_VALUE_CONFIRMED]: {
    id: HOTEL_RATING_STATUS_VALUE_CONFIRMED,
    label: "confirmé",
    label2: "favorable",
    raterLabel: "confirmé",
    color: "#27c24c",
    chakraColor: "green",
    io5Icon: "IoCheckmark",
  },
  [HOTEL_RATING_STATUS_VALUE_REFUSED]: {
    id: HOTEL_RATING_STATUS_VALUE_REFUSED,
    label: "refusé",
    label2: "défavorable",
    raterLabel: "refusé",
    color: "#f05050",
    chakraColor: "red",
    io5Icon: "IoClose",
  },
};

export const HOTEL_RATING_STATUS_LIST = Object.values(HOTEL_RATING_STATUSES);

// Valeur de classement pour les étoiles

/** Oui */
export const HOTEL_RATING_VIRTUAL_STAR_EVALUATION_VALUE_YES = "yes";
/** Non */
export const HOTEL_RATING_VIRTUAL_STAR_EVALUATION_VALUE_NO = "no";
/** Sans objet */
export const HOTEL_RATING_VIRTUAL_STAR_EVALUATION_VALUE_NA = "na";

export const HOTEL_RATING_VIRTUAL_STAR_EVALUATIONS = {
  [HOTEL_RATING_VIRTUAL_STAR_EVALUATION_VALUE_YES]: {
    id: HOTEL_RATING_VIRTUAL_STAR_EVALUATION_VALUE_YES,
    label: "oui",
    label2: "oui",
  },
  [HOTEL_RATING_VIRTUAL_STAR_EVALUATION_VALUE_NO]: {
    id: HOTEL_RATING_VIRTUAL_STAR_EVALUATION_VALUE_NO,
    label: "non",
    label2: "non",
  },
  [HOTEL_RATING_VIRTUAL_STAR_EVALUATION_VALUE_NA]: {
    id: HOTEL_RATING_VIRTUAL_STAR_EVALUATION_VALUE_NA,
    label: "sans objet",
    label2: "NA",
  },
};

export const HOTEL_RATING_VIRTUAL_STAR_EVALUATION_LIST = Object.values(
  HOTEL_RATING_VIRTUAL_STAR_EVALUATIONS,
);

// Statut possible pour les critères du classement en étoiles

export const HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X = "x";
export const HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O = "o";
export const HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC = "onc";
export const HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_NA = "na";

export const HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUSES = {
  [HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X]: {
    id: HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
    label: "obligatoire",
    label2: "Critères obligatoires",
    label3: "obligatoire",
  },
  [HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O]: {
    id: HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
    label: "à la carte",
    label2: "Critères « à la carte »",
    label3: "optionnel",
  },
  [HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC]: {
    id: HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
    label: "obligatoire non compensable",
    label2: "Critères « obligatoires non compensables »",
    label3: "obligatoire non compensable",
  },
  [HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_NA]: {
    id: HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_NA,
    label: "non applicable",
    label2: "Critères non applicables",
    label3: "non applicable",
  },
};

export const HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_LIST = Object.values(
  HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUSES,
);

// Evaluation meublés de tourisme

/**
 * @typedef HotelAppointmentVirtualRatingReportAttribute
 * @prop {string} id
 * @prop {string} name
 * @prop {"text" | "number" | "floor"} [type]
 * @prop {string} [note]
 * @prop {boolean} [objectless]
 * @prop {{[key: string]: HotelAppointmentVirtualRatingReportAttribute}} [children]
 */

/**
 * @typedef HotelAppointmentVirtualRatingReportDefinition
 * @prop {string} version
 * @prop {HotelAppointmentVirtualRatingReportAttribute[]} attributes
 */
export const HOTEL_RATING_VIRTUAL_STAR_RATING_REPORT_DEFINITION = {
  version: "1.0.0",
  evaluation: {
    attributes: {
      1: {
        id: "1",
        alias: "1",
        label: "Chapitre 1. Équipements et aménagements",
        children: {
          1: {
            id: "1",
            alias: "1-1",
            label: "1.1. Aménagement général",
            children: {
              1: {
                id: "1",
                alias: "1-1-1",
                label: "Équipements électriques de l’habitation",
                children: {
                  1: {
                    id: "1",
                    alias: "1-1-1-1",
                    points: 5,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "1. Surface totale minimum (cuisine et coin cuisine compris) du logement meublé hors salle d’eau et toilettes",
                    note: "Calculé automatiquement avec les informations spécifiées dans l’onglet « Pièces ».",
                  },
                  2: {
                    id: "2",
                    alias: "1-1-1-2",
                    points: 5,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "2. Surface totale majorée",
                    note: "Calculé automatiquement avec les informations spécifiées dans l’onglet « Pièces ».",
                  },
                  3: {
                    id: "3",
                    alias: "1-1-1-3",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "3. Prise de courant libre dans chaque pièce d'habitation",
                  },
                  4: {
                    id: "4",
                    alias: "1-1-1-4",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "4. Tous les éclairages du logement fonctionnent et sont en bon état",
                  },
                },
              },
              2: {
                id: "2",
                alias: "1-1-2",
                label: "Téléphonie et communication",
                children: {
                  1: {
                    id: "1",
                    alias: "1-1-2-1",
                    points: 1,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "5. Mise à disposition d'un téléphone privatif à l’intérieur du logement",
                    note: "Téléphone en commun non accepté.\nSauf impossibilité technique (ex : zones blanches justifiées), alors le critère est non applicable.",
                  },
                  2: {
                    id: "2",
                    alias: "1-1-2-2",
                    points: 2,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "6. Accès internet par un réseau local sans fil (Wi-Fi)",
                    note: "Sauf impossibilité technique (ex : zones blanches justifiées), alors le critère est non applicable.",
                  },
                  3: {
                    id: "3",
                    alias: "1-1-2-3",
                    points: 2,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "7. Accès internet filaire avec câble fourni",
                    note: "Sauf impossibilité technique (ex : zones blanches justifiées), alors le critère est non applicable.",
                  },
                },
              },
              3: {
                id: "3",
                alias: "1-1-3",
                label: "Télévision et équipement hi-fi",
                children: {
                  1: {
                    id: "1",
                    alias: "1-1-3-1",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "8. Télévision à écran plat avec télécommande",
                  },
                  2: {
                    id: "2",
                    alias: "1-1-3-2",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "9. Accès à des chaînes supplémentaires à l'offre de la TNT",
                  },
                  3: {
                    id: "3",
                    alias: "1-1-3-3",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "10. Possibilité d'accéder à au moins deux chaînes internationales",
                  },
                  4: {
                    id: "4",
                    alias: "1-1-3-4",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "11. Radio",
                  },
                  5: {
                    id: "5",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "12. Enceinte connectée",
                    alias: "1-1-3-5",
                  },
                  6: {
                    id: "6",
                    alias: "1-1-3-6",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "13. Mise à disposition d'un système de lecture vidéos",
                  },
                },
              },
              4: {
                id: "4",
                alias: "1-1-4",
                label: "Équipements pour le confort du client",
                children: {
                  1: {
                    id: "1",
                    alias: "1-1-4-1",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "14. Occultation opaque: extérieure ou intérieure dans chaque pièce comportant un couchage principal",
                  },
                  2: {
                    id: "2",
                    alias: "1-1-4-2",
                    points: 3,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "15. Le logement est équipé de double vitrage",
                    note: "Ce critère est non applicable en cas d'impossibilité architecturale et/ ou impossibilité locale justifiée.",
                  },
                  3: {
                    id: "3",
                    alias: "1-1-4-3",
                    points: 5,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "16. Existence d'un système de chauffage en état de fonctionnement",
                    note: "Un système de chauffage présent dans toutes les pièces d'habitation y compris la (ou les) salle(s) d'eau.\nSauf exception justifiée par le climat (DROM-COM), alors le critère est non applicable.",
                  },
                  4: {
                    id: "4",
                    alias: "1-1-4-4",
                    na: true,
                    points: 3,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "17. Existence d'un système de climatisation et / ou de rafraichissement d'air en état de fonctionnement",
                    note: "Sauf Saint Pierre et Miquelon et haute montagne (>1000 mètres d'altitude) alors le critère est non applicable.\nA vérifier par tout moyen.",
                  },
                  5: {
                    id: "5",
                    alias: "1-1-4-5",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "18. Machine à laver le linge pour les logements de 4 personnes et plus",
                    note: "Pour les catégories 1 et 2 étoiles, équipement pouvant être commun à plusieurs logements, avec accès indépendant, libre et gratuit.\nPour les catégories 3, 4 et 5 étoiles, lave-linge privatif obligatoire - tolérance dans un local privatif extérieur au logement.",
                  },
                  6: {
                    id: "6",
                    alias: "1-1-4-6",
                    points: 2,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "19. Sèche-linge pour les logements de 6 personnes et plus",
                    note: "Pour les catégories 1, 2 et 3 étoiles, équipement pouvant être commun à plusieurs logements avec accès indépendant et accessible librement et gratuitement.\nPour les catégories 4 et 5 étoiles, sèche-linge privatif obligatoire - tolérance dans un local privatif extérieur au logement. Sauf exception justifiée par le climat (DROM-COM), alors le critère est non applicable.",
                  },
                  7: {
                    id: "7",
                    alias: "1-1-4-7",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "20. Étendoir ou séchoir à linge à l'intérieur du logement",
                  },
                  8: {
                    id: "8",
                    alias: "1-1-4-8",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "21. Ustensiles de ménage appropriés au logement",
                    note: "Minimum : un seau et un balai brosse avec serpillère ou balai de lavage à frange avec seau et presse, aspirateur ou équipement équivalent",
                  },
                  9: {
                    id: "9",
                    alias: "1-1-4-9",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "22. Fer et table à repasser",
                  },
                },
              },
              5: {
                id: "5",
                alias: "1-1-5",
                label: "Mobiliers",
                children: {
                  1: {
                    id: "1",
                    alias: "1-1-5-1",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_NA,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_NA,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_NA,
                    },
                    label:
                      "23. Placards ou éléments de rangement dans le logement",
                  },
                  2: {
                    id: "2",
                    alias: "1-1-5-2",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "24. Placards ou élément de rangement dans chaque pièce d'habitation",
                    note: "Obligatoire dans chaque pièce d'habitation pour les catégories 3, 4 et 5 étoiles.",
                  },
                  3: {
                    id: "3",
                    alias: "1-1-5-3",
                    points: 4,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "25. Présence d'une table et des assises correspondant à la capacité d'accueil du logement",
                  },
                  4: {
                    id: "4",
                    alias: "1-1-5-4",
                    points: 3,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "26. Présence d'un canapé ou fauteuil(s) adapté(s) à la capacité d'accueil",
                    note: "Le critère devient non applicable s'il n'y a pas de séjour.",
                  },
                  5: {
                    id: "5",
                    alias: "1-1-5-5",
                    points: 1,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "27. Présence d'une table basse",
                  },
                },
              },
            },
          },
          2: {
            id: "2",
            alias: "1-2",
            label: "1.2. Aménagement des chambres",
            children: {
              1: {
                id: "1",
                alias: "1-2-1",
                label: "Literie",
                note: "Lit 1 personne : 1 étoile = 80x190cm, 2 et 3 étoiles = 90x190cm, 4 et 5 étoiles = 90x200cm \nLit 2 personnes : 1, 2 et 3 étoiles = 140x190cm, 4 et 5 étoiles = 160x200cm",
                children: {
                  1: {
                    id: "1",
                    alias: "1-2-1-1",
                    points: 4,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "28. Respect des dimensions du ou des lit(s)",
                    note: "Tolérance pour :\n- lits superposés (80cmx190cm)\n- lits jumeaux ou lits gigogne (80cmx190cm) pouvant être assemblés en lit double de 160 cm\n- canapé-lits convertibles (130cmx190cm) - uniquement pour les studios de catégorie 1 et 2 étoiles\n- lits escamotables pour les studios (toutes catégories)",
                  },
                  2: {
                    id: "2",
                    alias: "1-2-1-2",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "29. Matelas haute densité et / ou avec une épaisseur de qualité",
                    note: "La densité doit être supérieure à 30 kg/m3 ou équivalent. L'épaisseur doit être minimum de 21 cm.",
                  },
                  3: {
                    id: "3",
                    alias: "1-2-1-3",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "30. Présence d'oreiller(s) en quantité suffisante",
                    note: "Un oreiller par personne pour les catégories 1 et 2 étoiles. Deux oreillers par personne pour les catégories 3, 4 et 5 étoiles.",
                  },
                  4: {
                    id: "4",
                    alias: "1-2-1-4",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "31. Deux couvertures ou une couette par lit",
                    note: "Les plaids sont autorisés pour valider le critère dans les DROM-COM.",
                  },
                  5: {
                    id: "5",
                    alias: "1-2-1-5",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "32. Matelas protégés par les alaises ou des housses amovibles",
                  },
                  6: {
                    id: "6",
                    alias: "1-2-1-6",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "33. Éclairage en-tête de lit par personne avec interrupteur individuel",
                  },
                  7: {
                    id: "7",
                    alias: "1-2-1-7",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "34. Interrupteur ou système de commande de l'éclairage central du lit",
                  },
                  8: {
                    id: "8",
                    alias: "1-2-1-8",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "35. Présence d'une prise de courant libre près du lit",
                  },
                  9: {
                    id: "9",
                    alias: "1-2-1-9",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "36. Présence d'une table de chevet par personne",
                  },
                },
              },
            },
          },
          3: {
            id: "3",
            alias: "1-3",
            label: "1.3. Équipements et aménagement des sanitaires",
            children: {
              1: {
                id: "1",
                alias: "1-3-1",
                children: {
                  1: {
                    id: "1",
                    alias: "1-3-1-1",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "37. Une salle d'eau privative dans un espace clos et aéré intérieur au logement",
                  },
                  2: {
                    id: "2",
                    alias: "1-3-1-2",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "38. Une salle d'eau privative avec accès indépendant dans un espace intérieur au logement",
                  },
                  3: {
                    id: "3",
                    alias: "1-3-1-3",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_NA,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_NA,
                    },
                    label: "39. Présence d'une salle d'eau ainsi équipée :",
                    note: "- un lavabo avec eau chaude\n- une douche et / ou une baignoire (équipée d'une douchette) avec pare-douche ; une baignoire et une douche",
                  },
                  4: {
                    id: "4",
                    alias: "1-3-1-4",
                    note: "- un lavabo avec eau chaude\n- une douche (dimensions supérieures au standard) et / ou une baignoire (équipée d'une douchette) avec pare-douche (dimension supérieures au standard) ; une baignoire et une douche\n\nDimension douche standard : 80cm x 80cm\nDimension baignoire standard : 170cm x 75cm",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "40. Présence d'une salle d'eau ainsi équipée :",
                  },
                  5: {
                    id: "5",
                    alias: "1-3-1-5",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_NA,
                    },
                    label:
                      "41. Un WC (avec cuvette, abattant, chasse d'eau, dérouleur et poubelle) privatif intérieur au logement",
                    note: "Toilette sèche acceptée",
                  },
                  6: {
                    id: "6",
                    alias: "1-3-1-6",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "42. Un WC (avec cuvette, abattant, chasse d'eau, dérouleur et poubelle) privatif intérieur au logement indépendant de la salle d'eau",
                    note: "Toilette sèche acceptée",
                  },
                  7: {
                    id: "7",
                    alias: "1-3-1-7",
                    points: 5,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "43. Une deuxième salle d'eau privative dans un espace clos et aéré intérieur au logement avec accès indépendant",
                    note: "Pour les logements de moins de 7 personnes, ce critère est non applicable en 1, 2, 3, 4 étoiles Pour les logements de moins de 5 personnes, ce critère est non applicable en 5*.\n\nSi toutes les chambres sont équipées de salles d'eau, alors le critère est validé.\n\nLe critère peut être validé si cette deuxième salle d'eau n'a pas d'accès indépendant; uniquement si la première salle d'eau (jusqu'à 6 personnes) a bien un accès indépendant.",
                  },
                  8: {
                    id: "8",
                    alias: "1-3-1-8",
                    note: "- Un lavabo avec eau chaude\n- Une douche et / ou une baignoire (équipée d'une douchette) avec pare-douche\n- Une baignoire et une douche\n\nDimension douche standard : 80cm x 80cm\nDimension baignoire standard : 170cm x 75cm\nPour les logements de moins de 7 personnes, ce critère est non applicable en 1, 2, 3, 4 étoiles.\nPour les logements de moins de 5 personnes, ce critère est non applicable en 5 étoiles.",
                    points: 3,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "44. Présence d'une deuxième salle d'eau ainsi équipée :",
                  },
                  9: {
                    id: "9",
                    alias: "1-3-1-9",
                    note: "Toilette sèche acceptée\nPour les logements de moins de 7 personnes, ce critère est non applicable en 1, 2, 3, 4 étoiles\nPour les logements de moins de 5 personnes, ce critère est non applicable en 5 étoiles.",
                    points: 2,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "45. Un deuxième WC (avec cuvette, abattant, chasse d'eau, dérouleur et poubelle) privatif intérieur au logement",
                  },
                },
              },
              2: {
                id: "2",
                alias: "1-3-2",
                label: "Équipements salle(s) d’eau",
                children: {
                  1: {
                    id: "1",
                    alias: "1-3-2-1",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "46. Deux points lumineux dont un sur le lavabo",
                  },
                  2: {
                    id: "2",
                    alias: "1-3-2-2",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "47. Présence de produits d'accueil",
                  },
                  3: {
                    id: "3",
                    alias: "1-3-2-3",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "48. Une prise de courant libre à proximité du miroir",
                  },
                  4: {
                    id: "4",
                    alias: "1-3-2-4",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "49. Patère(s) ou porte-serviettes",
                  },
                  5: {
                    id: "5",
                    alias: "1-3-2-5",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "50. Sèche-serviette électrique",
                  },
                  6: {
                    id: "6",
                    alias: "1-3-2-6",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "51. Miroir de salle de bain",
                  },
                  7: {
                    id: "7",
                    alias: "1-3-2-7",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "52. Miroir en pied",
                  },
                  8: {
                    id: "8",
                    alias: "1-3-2-8",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "53. Tablette sous miroir, plan vasque ou étagère proche du miroir",
                  },
                  9: {
                    id: "9",
                    alias: "1-3-2-9",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "54. Espace(s) de rangement supplémentaire(s)",
                  },
                  10: {
                    id: "10",
                    alias: "1-3-2-10",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "55. Sèche-cheveux électrique en nombre suffisant",
                    note: "Un deuxième sèche-cheveux est exigé dans le logement à partir de 7 personnes",
                  },
                },
              },
            },
          },
          4: {
            id: "4",
            alias: "1-4",
            label:
              "Équipements et aménagement de la cuisine ou du coin cuisine",
            children: {
              1: {
                id: "1",
                alias: "1-4-1",
                label: "Bac(s) à laver",
                children: {
                  1: {
                    id: "1",
                    alias: "1-4-1-1",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "56. Évier avec robinet mélangeur ou mitigeur",
                  },
                },
              },
              2: {
                id: "2",
                alias: "1-4-2",
                label: "Appareils de cuisson",
                children: {
                  1: {
                    id: "1",
                    alias: "1-4-2-1",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "57. Table de cuisson : Nombre de foyers respectés",
                    note: "Table de cuisson pour logement jusqu'à 4 personnes (inclus) :\n• 2 foyers pour 1 et 2 étoiles\n• 4 foyers pour 3, 4 et 5 étoiles\n\nTable de cuisson pour logement à partir de 5 personnes :\n• 4 foyers pour 1, 2, 3, 4 et 5 étoiles\n\nPour les exigences d'une plaque à 4 foyers, si le logement est équipé d'une plaque à induction ou vitrocéramique à 3 foyers, alors le critère est réputé acquis.",
                  },
                  2: {
                    id: "2",
                    alias: "1-4-2-2",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "58. Plaque vitrocéramique, à induction ou à gaz",
                  },
                  3: {
                    id: "3",
                    alias: "1-4-2-3",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "59. Four ou mini-four",
                  },
                  4: {
                    id: "4",
                    alias: "1-4-2-4",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "60. Four à micro-ondes",
                  },
                  5: {
                    id: "5",
                    alias: "1-4-2-5",
                    points: 4,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "61. Ventilation ou ventilation mécanique contrôlée",
                    note: '"Ventilation" : aération naturelle dans la cuisine ou le coin cuisine',
                  },
                  6: {
                    id: "6",
                    alias: "1-4-2-6",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "62. Hotte aspirante",
                  },
                },
              },
              3: {
                id: "3",
                alias: "1-4-3",
                label: "Vaisselle et matériels de cuisson",
                children: {
                  1: {
                    id: "1",
                    alias: "1-4-3-1",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "63. Quantité de vaisselle de table non dépareillée minimum par personne :",
                    note: "Quantité de vaisselle de table non dépareillée minimum par personne :\n2 verres à eau, 1 verre à vin, 2 assiettes plates, 2 assiettes creuses, 2 assiettes à dessert, 2 grandes cuillères, 2 petites cuillères, 2 couteaux, 2 fourchettes, 2 bols, 2 tasses ou mugs",
                  },
                  2: {
                    id: "2",
                    alias: "1-4-3-2",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "64. Vaisselle supplémentaire :",
                    note: "1 coupe à champagne, 1 verre à apéritif par personne",
                  },
                  3: {
                    id: "3",
                    alias: "1-4-3-3",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "65. Équipement minimum pour la préparation des repas :",
                    note: "1 saladier, 1 plat allant au four, 2 casseroles, 1 poêle, 1 fait-tout, 1 tire-bouchon, 1 décapsuleur, 1 paire de ciseaux, 1 planche à découper, 1 couteau à pain, 1 passoire, 1 couvercle, 1 essoreuse à salade, 1 moule à tarte et/ou moule à gâteau, 1 ouvre-boîte, 1 économe, 1 dessous de plat, 1 verre doseur, 1 louche, 1 écumoir, 1 spatule, 1 fouet",
                  },
                  4: {
                    id: "4",
                    alias: "1-4-3-4",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "66. Au moins deux équipements de petit électroménager",
                  },
                  5: {
                    id: "5",
                    alias: "1-4-3-5",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "67. Autocuiseur ou cuit-vapeur ou robot de cuisine multifonctions",
                  },
                },
              },
              4: {
                id: "4",
                alias: "1-4-4",
                label: "Autres matériels",
                children: {
                  1: {
                    id: "1",
                    alias: "1-4-4-1",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "68. Cafetière",
                  },
                  2: {
                    id: "2",
                    alias: "1-4-4-2",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "69. Machine à expresso",
                  },
                  3: {
                    id: "3",
                    alias: "1-4-4-3",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "70. Bouilloire",
                  },
                  4: {
                    id: "4",
                    alias: "1-4-4-4",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "71. Grille-pain",
                  },
                  5: {
                    id: "5",
                    alias: "1-4-4-5",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "72. Lave-vaisselle pour les logements à partir de 2 personnes",
                  },
                  6: {
                    id: "6",
                    alias: "1-4-4-6",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "73. Lave-vaisselle de 6 couverts ou plus pour les logements à partir de 4 personnes",
                  },
                  7: {
                    id: "7",
                    alias: "1-4-4-7",
                    points: 4,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "74. Réfrigérateur avec compartiment conservateur",
                    note: "110 litres pour 2 personnes, 10 litres en plus par occupant supplémentaire",
                  },
                  8: {
                    id: "8",
                    alias: "1-4-4-8",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "75. Présence d'un congélateur ou d'un compartiment congélateur",
                  },
                  9: {
                    id: "9",
                    alias: "1-4-4-9",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "76. Poubelle fermée avec couvercle",
                  },
                },
              },
            },
          },
          5: {
            id: "5",
            alias: "1-5",
            label: "1.5. Environnement et extérieurs",
            children: {
              1: {
                id: "1",
                alias: "1-5-1",
                label: "Ascenseurs",
                children: {
                  1: {
                    id: "1",
                    alias: "1-5-1-1",
                    points: 4,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_NA,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_NA,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_NA,
                    },
                    label:
                      "77. Pour accéder au 4ème étage à partir du rez-de-chaussée",
                    note: "Sauf contrainte locale ou architecturale.\nSi le logement est situé en rez-de-chaussée, alors lorsque le critère est obligatoire il devient non applicable.\nSi le logement est situé entre le 1er et 3ème étage, alors lorsque le critère est obligatoire il devient optionnel.",
                  },
                  2: {
                    id: "2",
                    alias: "1-5-1-2",
                    points: 4,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "78. Pour accéder au 3ème étage à partir du rez-de-chaussée",
                    note: "Sauf contrainte locale ou architecturale.\nPour les catégories 1 et 2 étoiles, les points se cumulent avec ceux du critère précédent.\nSi le logement est situé en rez-de-chaussée, alors lorsque le critère est obligatoire il devient non applicable.\nSi le logement est situé entre le 1er et 2ème étage, alors lorsque le critère est obligatoire il devient optionnel.",
                  },
                },
              },
              2: {
                id: "2",
                alias: "1-5-2",
                label: "Parking voiture",
                children: {
                  1: {
                    id: "1",
                    alias: "1-5-2-1",
                    points: 4,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "79. Emplacement(s) à proximité",
                  },
                  2: {
                    id: "2",
                    alias: "1-5-2-2",
                    points: 3,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "80. Emplacement(s) privatif(s)",
                  },
                  3: {
                    id: "5",
                    alias: "1-5-2-3",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "81. Garage ou abri couvert privatif",
                  },
                },
              },
              3: {
                id: "3",
                alias: "1-5-3",
                label: "Balcon, loggia, terrasse, jardin",
                children: {
                  1: {
                    id: "1",
                    alias: "1-5-3-1",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "82. Logement avec balcon, loggia ou veranda",
                    note: "Surface minimum de 3m²",
                  },
                  2: {
                    id: "2",
                    alias: "1-5-3-2",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "83. Logement avec terrasse ou jardin privé",
                    note: "Surface minimum de 8m²\nCour intérieure aménagée tolérée",
                  },
                  3: {
                    id: "3",
                    alias: "1-5-3-3",
                    points: 4,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "84. Logement avec parc ou jardin",
                    note: "Surface minimum de 50m²\n200m² minimum quand il est commun à d'autres logements",
                  },
                  4: {
                    id: "4",
                    alias: "1-5-3-4",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "85. Présence de mobilier de jardin privatif propre et en bon état",
                  },
                  5: {
                    id: "5",
                    alias: "1-5-3-5",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "86. Mise à disposition d'une plancha extérieure et/ou d'un barbecue extérieur",
                  },
                },
              },
              4: {
                id: "4",
                alias: "1-5-4",
                label:
                  "Équipements de loisirs, détente, sports, attachés au logement",
                children: {
                  1: {
                    id: "1",
                    alias: "1-5-4-1",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "87. Un équipement léger de loisirs, détente ou sport dédié au logement",
                    note: "Accès gratuit en propriété pleine ou copropriété",
                  },
                  2: {
                    id: "2",
                    alias: "1-5-4-2",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "88. Un équipement aménagé de loisirs, détente ou sport dédié au logement",
                    note: "Accès gratuit en propriété pleine ou copropriété",
                  },
                  3: {
                    id: "3",
                    alias: "1-5-4-3",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "89. Piscine extérieure ou intérieure",
                    note: "Accès gratuit en propriété pleine ou copropriété",
                  },
                  4: {
                    id: "4",
                    alias: "1-5-4-4",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "90. Piscine extérieure ou intérieure chauffée",
                    note: "Accès gratuit en propriété pleine ou copropriété",
                  },
                  5: {
                    id: "5",
                    alias: "1-5-4-5",
                    points: 1,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "91. Existence de rangement(s) pour équipement sportif",
                    note: "Accès gratuit en propriété pleine ou copropriété\nNA si localisation non adaptée.",
                  },
                },
              },
              5: {
                id: "5",
                alias: "1-5-5",
                label: "Environnement",
                children: {
                  1: {
                    id: "1",
                    alias: "1-5-5-1",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "92. Logement avec vue paysagère",
                    note: "Vue mer, montagne, plaine ou zone urbaine",
                  },
                  2: {
                    id: "2",
                    alias: "1-5-5-2",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "93. Logement avec accès immédiat à un environnement offrant la possibilité de faire des activités : naturelles, culturelles ou sportives",
                    note: "Accès situé à 1km maximum",
                  },
                  3: {
                    id: "3",
                    alias: "1-5-5-3",
                    note: "Accès situé à 1km maximum",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "94. Logement avec accès immédiat aux commerces, services et transport en commun",
                  },
                },
              },
            },
          },
          6: {
            id: "6",
            alias: "1-6",
            label: "1.6. État et propreté des installations et des équipements",
            children: {
              1: {
                id: "1",
                alias: "1-6-1",
                children: {
                  1: {
                    id: "1",
                    alias: "1-6-1-1",
                    points: 5,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                    },
                    label:
                      "95. Les sanitaires (toilette(s) et salle(s) d'eau) sont propres et en bon état",
                    note: "Le sol, le plafond, la paroi de douche (ou rideau de douche) et les murs sont propres et en bon état (absence de moisissures, saletés, traces de calcaire, cheveux...). Absence de carrelage cassé et de revêtement abimé.",
                  },
                  2: {
                    id: "2",
                    alias: "1-6-1-2",
                    points: 5,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                    },
                    label:
                      "96. Les sols, murs et plafonds sont propres et en bon état",
                    note: "Absence de saletés sur les murs, de peintures écaillées, de papier peint abîmé et déchiré, de carrelage cassé, de moquette tâchée, de traces de moisissures...",
                  },
                  3: {
                    id: "3",
                    alias: "1-6-1-3",
                    points: 5,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                    },
                    label: "97. Le mobilier est propre et en bon état",
                    note: "Absence de mobiliers cassés, déboités et sales.",
                  },
                  4: {
                    id: "4",
                    alias: "1-6-1-4",
                    points: 5,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                    },
                    label: "98. La literie est propre et en bon état",
                    note: "Ensemble constitué par matelas et sommier. Absence de tâches, de trous, de décoloration.",
                  },
                  5: {
                    id: "5",
                    alias: "1-6-1-5",
                    points: 5,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_ONC,
                    },
                    label:
                      "99. La cuisine ou coin cuisine et les équipements sont propres et en bon état",
                    note: "Absence d'équipements cassés, déboités et sales. Absence de saletés sur les murs, de peintures écaillées, de carrelage cassé, de traces de moisissures...",
                  },
                },
              },
            },
          },
        },
      },
      2: {
        id: "2",
        label: "Chapitre 2. Services aux clients",
        alias: "2",
        children: {
          1: {
            id: "1",
            alias: "2-1",
            label: "2.1. Qualité et fiabilité de l'information client",
            children: {
              1: {
                id: "1",
                alias: "2-1-1",
                children: {
                  1: {
                    id: "1",
                    alias: "2-1-1-1",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "100. Mise à disposition de brochures d'informations locales et touristiques en français et dans au moins une lange étrangère",
                  },
                  2: {
                    id: "2",
                    alias: "2-1-1-2",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "101. Mise à disposition d'un livret d'accueil",
                  },
                },
                label: "",
              },
            },
          },
          2: {
            id: "2",
            alias: "2-2",
            label: "2.2. Les services proposés",
            children: {
              1: {
                id: "1",
                alias: "2-2-1",
                children: {
                  1: {
                    id: "1",
                    alias: "2-2-1-1",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "102. Accueil sur place par le propriétaire ou son représentant",
                  },
                  2: {
                    id: "2",
                    alias: "2-2-1-2",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "103. Cadeau de bienvenue à l'arrivée du client",
                  },
                  3: {
                    id: "3",
                    alias: "2-2-1-3",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "104. Existence d'une boîte à clé ou système équivalent",
                  },
                  4: {
                    id: "4",
                    alias: "2-2-1-4",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "105. Draps de lit proposés systématiquement par le loueur",
                  },
                  5: {
                    id: "5",
                    alias: "2-2-1-5",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "106. Linge de toilette proposé systématiquement par le loueur",
                  },
                  6: {
                    id: "6",
                    alias: "2-2-1-6",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "107. Linge de table",
                  },
                  7: {
                    id: "7",
                    alias: "2-2-1-7",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "108. Lits fait à l'arrivée proposé systématiquement par le loueur",
                  },
                  8: {
                    id: "8",
                    alias: "2-2-1-8",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "109. Matériel pour bébé à la demande",
                  },
                  9: {
                    id: "9",
                    alias: "2-2-1-9",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "110. Service de ménage proposé systématiquement",
                  },
                  10: {
                    id: "10",
                    alias: "2-2-1-10",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "111. Présence de produits d'entretien",
                  },
                  11: {
                    id: "11",
                    alias: "2-2-1-11",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "112. Adaptateurs électriques",
                  },
                  12: {
                    id: "12",
                    alias: "2-2-1-12",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "113. Existence d'un site internet ou d'une page internet dédiée au logement",
                  },
                  13: {
                    id: "13",
                    alias: "2-2-1-13",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "114. Existence d'un site internet ou d'une page internet dédiée au logement en langue étrangère",
                  },
                  14: {
                    id: "14",
                    alias: "2-2-1-14",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "115. Animaux de compagnie admis",
                  },
                },
                label: "",
              },
            },
          },
        },
      },
      3: {
        id: "3",
        alias: "3",
        label: "Chapitre 3. Accessibilité et développement durable",
        children: {
          1: {
            id: "1",
            alias: "3-1",
            label: "3.1. Accessibilité",
            children: {
              1: {
                id: "1",
                alias: "3-1-1",
                label: "Information et sensibilisation",
                children: {
                  1: {
                    id: "1",
                    alias: "3-1-1-1",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "116. Informations concernant l'accessibilité sur les support d'information",
                  },
                },
              },
              2: {
                id: "2",
                alias: "3-1-2",
                label: "Autres services",
                children: {
                  1: {
                    id: "1",
                    alias: "3-1-2-1",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "117. Mise à disposition de télécommande de télévision à grosse touches et de couleur contrastée",
                  },
                  2: {
                    id: "2",
                    alias: "3-1-2-2",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "118. Présence d'un siège de douche avec barre d'appui",
                  },
                  3: {
                    id: "3",
                    alias: "3-1-2-3",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "119. Présence de WC avec barre d'appui",
                  },
                  4: {
                    id: "4",
                    alias: "3-1-2-4",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "120. Largeur de toutes les portes adaptées",
                    note: "Minimum 0,77m (passage utile).",
                  },
                  5: {
                    id: "5",
                    alias: "3-1-2-5",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label: "121. Document accessible mis à disposition",
                    note: "Si le meublé est labellisé Tourisme et Handicap, le critère est validé.",
                  },
                  6: {
                    id: "6",
                    alias: "3-1-2-6",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "122. Le meublé est-il labellisé Tourisme et Handicap ?",
                  },
                },
              },
            },
          },
          2: {
            id: "2",
            alias: "3-2",
            label: "3.2. Développement durable",
            children: {
              1: {
                id: "1",
                alias: "3-2-1",
                children: {
                  1: {
                    id: "1",
                    alias: "3-2-1-1",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "123. Mise en oeuvre d'une mesure de réduction de consommation d'énergie",
                  },
                  2: {
                    id: "2",
                    alias: "3-2-1-2",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "124. Mise en oeuvre d'une mesure de réduction de consommation d'énergie supplémentaire",
                    note: "Les points se cumulent avec ceux du critère précédent.",
                  },
                  3: {
                    id: "3",
                    alias: "3-2-1-3",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "125. Borne de recharge pour véhicules électriques",
                  },
                  4: {
                    id: "4",
                    alias: "3-2-1-4",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "126. Mise en oeuvre d'une mesure de réduction de consommation d'eau",
                  },
                  5: {
                    id: "5",
                    alias: "3-2-1-5",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "127. Mise en oeuvre d'une mesure de réduction de consommation d'eau supplémentaire",
                    note: "Les points se cumulent avec ceux du critère précédent.",
                  },
                  6: {
                    id: "6",
                    alias: "3-2-1-6",
                    points: 1,
                    na: true,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "128. Existence d'un système de trie des déchets dédié au logement",
                  },
                  7: {
                    id: "7",
                    alias: "3-2-1-7",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "129. Existence d'un composteur",
                  },
                  8: {
                    id: "8",
                    alias: "3-2-1-8",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "130. Sensibilisation des clients sur les actions qu'ils peuvent réaliser lors de leur séjour en matière de respect de l'environnement",
                  },
                  9: {
                    id: "9",
                    alias: "3-2-1-9",
                    points: 2,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label:
                      "131. Présence de produits d'accueil écologiques dans la salle de bains",
                  },
                  10: {
                    id: "10",
                    alias: "3-2-1-10",
                    points: 1,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_X,
                    },
                    label:
                      "132. Mise à disposition d'au moins deux produits d'entretien respectueux de l'environnement",
                  },
                  11: {
                    id: "11",
                    alias: "3-2-1-11",
                    points: 3,
                    na: false,
                    status: {
                      [HOTEL_RATING_STAR_VALUE_1]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_2]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_3]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_4]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                      [HOTEL_RATING_STAR_VALUE_5]:
                        HOTEL_RATING_VIRTUAL_STAR_EVALUATION_STATUS_VALUE_O,
                    },
                    label: "133. Obtention d'un label environnemental",
                    note: "Les labels autorisés pour valider le critère sont les labels recommandés par l'ADEME.",
                  },
                },
                label: "",
              },
            },
          },
        },
      },
    },
  },
};

/**
 * @type {object}
 * @prop {string} version
 * @prop {HotelAppointmentVirtualRatingReportAttribute[]} attributes
 */
export const HOTEL_RATING_VIRTUAL_EPI_RATING_REPORT_DEFINITION = {
  version: "1.0.0",
  evaluation: {
    attributes: {
      1: {
        id: "1",
        alias: "1",
        label: "I. Environnement / Situation / Extérieurs",
        children: {
          1: {
            id: "1",
            alias: "1-1",
            label: "1. Accès carrossable de qualité jusqu'au meublé",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Dérogation possible si village fortifié / chemin ou route non goudronnée, ... (à préciser dans le descriptif)",
            allowComment: false,
          },
          2: {
            id: "2",
            alias: "1-2",
            label: "2. État extérieur du bâtiment",
            values: [],
            note: "Toiture, façades, huisseries, volets, balcons, gouttières",
            allowComment: false,
            children: {
              1: {
                id: "1",
                alias: "1-2-1",
                label: "Bon",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                note: "",
                allowComment: false,
              },
              2: {
                id: "2",
                alias: "1-2-1",
                label: "Très bon",
                values: [HOTEL_RATING_EPI_VALUE_3],
                note: "",
                allowComment: false,
              },
              3: {
                id: "3",
                alias: "1-2-1",
                label: "Excellent",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "",
                allowComment: false,
              },
            },
          },
          3: {
            id: "3",
            alias: "1-3",
            label: "3. Aspect architectural du bâtiment",
            values: [],
            note: "Bâtiment de qualité, quelle que soit son identité architecturale (ancien ou contemporain)",
            allowComment: false,
            children: {
              1: {
                id: "1",
                alias: "1-3-1",
                label: "Sans caractère ou style particulier",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                note: "",
                allowComment: false,
              },
              2: {
                id: "2",
                alias: "1-3-1",
                label: "Traditionnel, régional ou contemporain de style",
                values: [HOTEL_RATING_EPI_VALUE_3, HOTEL_RATING_EPI_VALUE_4],
                note: "",
                allowComment: false,
              },
              3: {
                id: "3",
                alias: "1-3-1",
                label: "De caractère ou exceptionnel",
                values: [HOTEL_RATING_EPI_VALUE_5],
                note: "",
                allowComment: false,
              },
            },
          },
          4: {
            id: "4",
            alias: "1-4",
            label: "4. Indépendance du meublé",
            values: [],
            note: "",
            allowComment: false,
            children: {
              1: {
                id: "1",
                alias: "1-4-1",
                label: "Entrée commune ou passage possible devant l'entrée",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                ],
                note: "",
                allowComment: false,
              },
              2: {
                id: "2",
                alias: "1-4-1",
                label: "Meublé avec entrée indépendante",
                values: [HOTEL_RATING_EPI_VALUE_4],
                note: "Mitoyen (vertical ou horizontal) ou indépendant\nPas de couloir commun",
                allowComment: false,
              },
              3: {
                id: "3",
                alias: "1-4-1",
                label: "Maison indépendante",
                values: [HOTEL_RATING_EPI_VALUE_5],
                note: "",
                allowComment: false,
              },
            },
          },
          5: {
            id: "5",
            alias: "1-5",
            label: "5. Espaces extérieurs (terrain, jardin, terrasse)",
            values: [],
            note: "Dérogation possible pour tout niveau de classement et tout type de territoire (centre bourg, village fortifié, ...)",
            allowComment: false,
            children: {
              1: {
                id: "1",
                alias: "1-5-1",
                label: "Espace extérieur commun",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                note: "Plusieurs gîtes et/ou avec propriétaire",
                allowComment: false,
              },
              2: {
                id: "2",
                alias: "1-5-1",
                label: "Espace extérieur attenant et privatif",
                values: [HOTEL_RATING_EPI_VALUE_3],
                note: "Clôture non obligatoire, tolérance possible si non immédiatement attenant au bâtiment (sauf 5 épis)",
                allowComment: false,
              },
              3: {
                id: "3",
                alias: "1-5-1",
                label: "Terrasse de qualité",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "Adaptée à la capacité / sauf contrainte architecturale\nUniquement si le meublé est situé au rdc en 3 et 4 épis",
                allowComment: false,
              },
              4: {
                id: "4",
                alias: "1-5-2",
                label: "Terrain aménagé et entretenu",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                note: "Cour ou jardinet aménagés tolérés",
                allowComment: false,
              },
              5: {
                id: "5",
                alias: "1-5-2",
                label: "Jardin soigné",
                values: [HOTEL_RATING_EPI_VALUE_3],
                note: "Une terrasse de qualité compense le critère",
                allowComment: false,
              },
              6: {
                id: "6",
                alias: "1-5-2",
                label: "Jardin d’agrément avec aménagement paysager",
                values: [HOTEL_RATING_EPI_VALUE_4],
                note: "Une terrasse avec aménagement paysager compense le critère",
                allowComment: false,
              },
              7: {
                id: "7",
                alias: "1-5-2",
                label: "Parc ou jardin paysager",
                values: [HOTEL_RATING_EPI_VALUE_5],
                note: "",
                allowComment: false,
              },
            },
          },
          6: {
            id: "6",
            alias: "1-6",
            label:
              "6. Fleurissement du terrain, terrasse, balcons, fenêtres, ...",
            values: [
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "",
            allowComment: false,
          },
          7: {
            id: "7",
            alias: "1-7",
            label: "7. Parking voiture",
            values: [],
            note: "Dérogation possible pour tout niveau de classement et tout type de territoire (centre bourg, village fortifié, ...)",
            allowComment: false,
            children: {
              1: {
                id: "1",
                alias: "1-7-1",
                label: "Emplacement public",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                note: "Doit être en toute proximité du bâtiment",
                allowComment: false,
              },
              2: {
                id: "2",
                alias: "1-7-1",
                label: "Emplacement privé",
                values: [
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Sur place ou en toute proximité (espace réservé, box, ...)",
                allowComment: false,
              },
              3: {
                id: "3",
                alias: "1-7-2",
                label: "Éclairage extérieur",
                values: [
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Depuis parking jusqu'à la porte d'entrée du gîte\nDétecteurs ou balisage écologique conseillés",
                allowComment: false,
              },
            },
          },
          8: {
            id: "8",
            alias: "1-8",
            label: "8. Équipements et mobilier extérieurs",
            values: [],
            note: "Parfait état exigé pour tous niveaux de classement\nStyle, design, confort, dimension, qualité matériaux, ...",
            allowComment: false,
            children: {
              1: {
                id: "1",
                alias: "1-8-1",
                label:
                  "Salon de jardin confortable adapté à la capacité (table + assises)",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Nombre d'assises supérieur à la capacité conseillé\nÉviter tables monobloc avec bancs (chaises conseillées)",
                allowComment: false,
              },
              2: {
                id: "2",
                alias: "1-8-2",
                label: "Salon de jardin simple",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                note: "Salon PVC basique ou bois, métal basique (aucun style)\nÉquipement commun toléré (pour 2 gîtes maximum)",
                allowComment: false,
              },
              3: {
                id: "3",
                alias: "1-8-2",
                label: "Salon de jardin privatif de qualité",
                values: [HOTEL_RATING_EPI_VALUE_3],
                note: 'Mobilier "déco" exigé: bois, métal, résine tressée, ...\nHarmonie exigée',
                allowComment: false,
              },
              4: {
                id: "4",
                alias: "1-8-2",
                label: "Salon de jardin privatif de grande qualité",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "Mobilier haut de gamme : résine-rotin tressé, tek, fer forgé, pierre, métal-plastique design, ... / bancs exclus",
                allowComment: false,
              },
              5: {
                id: "5",
                alias: "1-8-3",
                label:
                  "Salon de détente privatif de grande qualité (canapé, fauteuils, table basse, ...)",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "Adapté à la capacité, esthétique, confortable, haut de gamme : résine-rotin tressé, plastique design, rotin, ...",
                allowComment: false,
              },
              6: {
                id: "6",
                alias: "1-8-4",
                label: "Bains de soleil / chaises longues de qualité",
                values: [HOTEL_RATING_EPI_VALUE_3],
                note: "Au moins 50% de la capacité / publicitaire exclu",
                allowComment: false,
              },
              7: {
                id: "7",
                alias: "1-8-4",
                label: "Bains de soleil / chaises longues de grande qualité",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "100% de la capacité / publicitaire exclu\nTolérance pour les gîtes de grande capacité (> 7 personnes)",
                allowComment: false,
              },
              8: {
                id: "8",
                alias: "1-8-5",
                label:
                  "Parasol ou dispositif de protection solaire ou terrasse couverte / ombragée",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Parasol déporté conseillé en 3 épis / publicitaire exclu",
                allowComment: false,
              },
              9: {
                id: "9",
                alias: "1-8-6",
                label:
                  "Éclairage extérieur (porte d'entrée + balcon ou terrasse)",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Ampoules nues exclues (appliques, plafonniers, borne, ...)\nPrise électrique extérieure conseillée côté terrasse",
                allowComment: false,
              },
              10: {
                id: "10",
                alias: "1-8-7",
                label: "Séchoir / étendoir à linge intérieur ou extérieur",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Séchoir type parapluie ou étendoir scellé avec poteaux\nSimple fil toléré en 1 et 2 épis / avec pinces à linge\nTolérance étendoir pliant si uniquement terrasse\nSi intérieur : étendoir / séchoir pliant ou mural (sdb ou buanderie)",
                allowComment: false,
              },
              11: {
                id: "11",
                alias: "1-8-8",
                label: "Barbecue ou plancha",
                values: [
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: `Petit barbecue électrique toléré selon la capacité jusqu'en 2 épis / Barbecue ou plancha de qualité supérieure dès 4 épis / Avec ustensiles (spatule, fourchette, pince, brosse, ...)\nNormé NF / Barbecue "fait maison" exclu\nDérogation si interdiction local (dans ce cas plancha électrique à demander)`,
                allowComment: false,
              },
              12: {
                id: "12",
                alias: "1-8-9",
                label:
                  "Équipement de loisir (bien-être / sport / remise en forme)",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "Spa, hammam, sauna, piscine, tennis, salle de sport, ...\nConseiller table de ping-pong, trampoline, badminton, ... quel que soit le niveau de classement (normés NF) équipement de bien-être impératif en 5 épis",
                allowComment: false,
              },
            },
          },
          9: {
            id: "9",
            alias: "1-9",
            type: "surface",
            label: "9. Surface habitable des pièces de jour",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            meta: {
              rows: [
                HOTEL_RATING_EPIS[HOTEL_RATING_EPI_VALUE_1],
                HOTEL_RATING_EPIS[HOTEL_RATING_EPI_VALUE_2],
                HOTEL_RATING_EPIS[HOTEL_RATING_EPI_VALUE_3],
                HOTEL_RATING_EPIS[HOTEL_RATING_EPI_VALUE_4],
                HOTEL_RATING_EPIS[HOTEL_RATING_EPI_VALUE_5],
                { label: "Pers supp.", id: "custom" },
              ],
              columns: [
                {
                  label: "2 pers.",
                  ratingList: {
                    [HOTEL_RATING_EPI_VALUE_1]: { label: "10" },
                    [HOTEL_RATING_EPI_VALUE_2]: { label: "13" },
                    [HOTEL_RATING_EPI_VALUE_3]: { label: "16" },
                    [HOTEL_RATING_EPI_VALUE_4]: { label: "19" },
                    [HOTEL_RATING_EPI_VALUE_5]: { label: "22" },
                    custom: { label: "+ 3m²" },
                  },
                },
                {
                  label: "3 pers.",
                  ratingList: {
                    [HOTEL_RATING_EPI_VALUE_1]: { label: "13" },
                    [HOTEL_RATING_EPI_VALUE_2]: { label: "16" },
                    [HOTEL_RATING_EPI_VALUE_3]: { label: "19" },
                    [HOTEL_RATING_EPI_VALUE_4]: { label: "22" },
                    [HOTEL_RATING_EPI_VALUE_5]: { label: "25" },
                    custom: { label: "+ 3m²" },
                  },
                },
                {
                  label: "4 pers.",
                  ratingList: {
                    [HOTEL_RATING_EPI_VALUE_1]: { label: "16" },
                    [HOTEL_RATING_EPI_VALUE_2]: { label: "19" },
                    [HOTEL_RATING_EPI_VALUE_3]: { label: "22" },
                    [HOTEL_RATING_EPI_VALUE_4]: { label: "22" },
                    [HOTEL_RATING_EPI_VALUE_5]: { label: "28" },
                    custom: { label: "+ 3m²" },
                  },
                },
                {
                  label: "5 pers.",
                  ratingList: {
                    [HOTEL_RATING_EPI_VALUE_1]: { label: "19" },
                    [HOTEL_RATING_EPI_VALUE_2]: { label: "22" },
                    [HOTEL_RATING_EPI_VALUE_3]: { label: "25" },
                    [HOTEL_RATING_EPI_VALUE_4]: { label: "28" },
                    [HOTEL_RATING_EPI_VALUE_5]: { label: "31" },
                    custom: { label: "+ 3m²" },
                  },
                },
                {
                  label: "6 pers.",
                  ratingList: {
                    [HOTEL_RATING_EPI_VALUE_1]: { label: "22" },
                    [HOTEL_RATING_EPI_VALUE_2]: { label: "25" },
                    [HOTEL_RATING_EPI_VALUE_3]: { label: "28" },
                    [HOTEL_RATING_EPI_VALUE_4]: { label: "31" },
                    [HOTEL_RATING_EPI_VALUE_5]: { label: "23" },
                    custom: { label: "+ 3m²" },
                  },
                },
                {
                  label: "Épis sup.",
                  ratingList: {
                    [HOTEL_RATING_EPI_VALUE_1]: { label: "+ 3m²" },
                    [HOTEL_RATING_EPI_VALUE_2]: { label: "+ 3m²" },
                    [HOTEL_RATING_EPI_VALUE_3]: { label: "+ 3m²" },
                    [HOTEL_RATING_EPI_VALUE_4]: { label: "+ 3m²" },
                    [HOTEL_RATING_EPI_VALUE_5]: { label: "+ 3m²" },
                    custom: { label: "" },
                  },
                },
              ],
            },
            note: "Cumul si pièces séparées (cuisine, salon, séjour)\n\nAdaptations possibles en fonction des caractéristiques et contraintes architecturales et techniques\nSpécifiquement pour les gîtes situés au sein de villages fortifiés ou regroupés ou des appartements situés dans des immeubles, des bourgs ou petites villes\nDes stations thermales, stations de ski, stations balnéaires, ...\nPrime à donner à l'ergonomie, à la fonctionnalité-praticité et au confort global (qualité de l'aménagement-agencement de l'espace)",
            allowComment: false,
          },
          10: {
            id: "10",
            alias: "1-10",
            label: "10. Studio",
            values: [],
            note: "Open-space (quelle que soit la surface globale)\nLa qualité des couchages doit respecter par défaut l'exigence des couchages dans le séjour (critère n°13) et l'exigence standard (critères 46 et 47)",
            allowComment: false,
            children: {
              1: {
                id: "1",
                alias: "1-10-1",
                label: "2 personnes maximum si un seul niveau",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                ],
                note: "Possibilité 3 personnes selon qualité de l'agencement\nLits obligatoire en 4 épis (en+ du canapé détente)",
                allowComment: false,
              },
              2: {
                id: "2",
                alias: "1-10-2",
                label: "4 personnes possible si chambre / mezzanine (duplex)",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                ],
                note: "",
                allowComment: false,
              },
              3: {
                id: "3",
                alias: "1-10-3",
                label: "Surface minimum de 20 m²",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                ],
                note: "Base 2 personne. Bon agencement ergonomique",
                allowComment: false,
              },
              4: {
                id: "4",
                alias: "1-10-3",
                label: "Surface minimum de 30 m²",
                values: [HOTEL_RATING_EPI_VALUE_4],
                note: "Base 2 personnes",
                allowComment: false,
              },
            },
          },
          11: {
            id: "11",
            alias: "1-11",
            label: "11. Surface habitable des chambres",
            values: [],
            note: "À adapter selon la qualité de l'agencement et si meublé en appartement (logique identique aux pièces de jour)",
            allowComment: false,
            children: {
              1: {
                id: "1",
                alias: "1-11-1",
                label: "Chambre 1 personne",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                allowComment: false,
              },
              2: {
                id: "2",
                alias: "1-11-2",
                label: "Chambre 2 personnes",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                allowComment: false,
              },
              3: {
                id: "3",
                alias: "1-11-3",
                label: "Par lit supplémentaire au-delà de 2 personnes",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                ],
                allowComment: false,
              },
            },
          },
          12: {
            id: "12",
            alias: "1-12",
            label:
              "12. Capacité maxi en fonction du nombre de chambres C = capacité d'accueil / nombre de chambres",
            values: [],
            note: `Ratio qui correspond à la majorité des chambres tolérance chambres en mezzanines jusqu'en 4 épis (si non "palière" / palier qui dessert d'autres pièces)`,
            allowComment: false,
            children: {
              1: {
                id: "1",
                alias: "1-12-1",
                label: "C = 4",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                allowComment: false,
              },
              2: {
                id: "2",
                alias: "1-12-1",
                label: "C = 3",
                values: [HOTEL_RATING_EPI_VALUE_3],
                allowComment: false,
              },
              3: {
                id: "3",
                alias: "1-12-1",
                label: "C = 2",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                allowComment: false,
              },
            },
          },
        },
      },
      2: {
        id: "2",
        alias: "2",
        label: "II. Habitabilité",
        children: [
          {
            id: "13",
            alias: "2-13",
            label: "13. Couchage dans les pièces de jour",
            values: [],
            note: "Salon / Séjour-salon / Séjour-cuisine-salon (open-space)",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "2-13-1",
                label: "Couchage comptabilisé dans la capacité classée",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                note: "Convertible de qualité / lit escamotable / lit en alcôve, ...\nConvertible 130cm non tolérés pour 2 personnes (140cm mini)\nFuton, chauffeuse ou équivalents à ras du sol à éviter ",
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "2-13-1",
                label: "Couchage non comptabilisé dans la capacité classée",
                values: [
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Considéré uniquement en lit d'appoint si existant",
                allowComment: true,
              },
            },
          },
          {
            id: "14",
            alias: "2-14",
            label:
              "14. Sanitaires (nombre minimum requis)\n- Lors d'un reclassement, l'absence d'une 3ème salle d'eau / de bain ne pourra entraîner le déclassement de l'hébergement",
            values: [],
            note: "Accès indépendant exigé (tolérance si 2 chambres) toilettes sèche (selon les cas) et salle de bain ouvertes tolérées\nValidation si salle d'eau / de bain privatives dans chaque chambre\nAdaptation possible concernant l'indépendance des wc et la présence de lave-mains pour les candidatures spontanées selon la capacité d'accueil et en fonction des impossibilités architecturales et/ou techniques\n1 sanitaire privatif complet par chambre fortement conseillé en 5 épis\n\nCapacité ⩽ 6 personnes : 1 sanitaire complet\nCapacité 7 à 12 personnes : 2 sanitaires complets\nCapacité ⩾ 13 personnes : 3 sanitaires complets",
            children: {
              1: {
                id: "1",
                alias: "2-14-1",
                label: "1 salle d'eau / bain avec accès indépendant",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Lavabo + douche ou baignoire (pour toute capacité)",
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "2-14-2",
                label: "1 WC",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "WC indépendant avec lave-mains conseillé",
                allowComment: true,
              },
            },
          },
          {
            id: "15",
            alias: "2-15",
            label: "15. Escalier confortable muni d’une rampe",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: 'Tolérance échelles de meuniers, escaliers japonais, népalais ou "design" (à préciser dans le descriptif)\nConseiller barrière sécurité bébé-enfants (portail)',
            allowComment: true,
          },
          {
            id: "16",
            alias: "2-16",
            label: "16. Luminosité naturelle suffisante dans chaque pièce",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Suivant le bâti local. Un ouvrant direct sur l'extérieur obligatoire",
            allowComment: true,
          },
          {
            id: "17",
            alias: "2-17",
            label: "17. Vue latérale dans la pièce de vie et les chambres",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: `Tolérance "velux" sans vue latérale dans les chambres :\n- Jusqu'en 3 épis en cas d'impossibilité architecturale\n- En 4 épis si ratio maximum de 50%`,
            allowComment: true,
          },
          {
            id: "18",
            alias: "2-18",
            label:
              "18. Occultation opaque extérieure et / ou intérieure dans les pièces avec couchages",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Volets (tous types) et / ou dispositif harmonieux (rideaux occultant / double-rideaux / stores, ...)",
            allowComment: true,
          },
        ],
      },
      3: {
        id: "3",
        alias: "3",
        label: "III. Équipements techniques",
        children: [
          {
            id: "19",
            alias: "3-19",
            label: "19. Ventilation cuisine",
            values: [],
            note: "VMC adaptée obligatoire ou a minima grille de ventilation haute et basse si appareils à combustion",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "3-19-1",
                label: "VMC ou hotte aspirante (à extraction ou recyclage)",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Tolérance aération naturelle en 1 et 2 épis (simple grille ou extracteur / fenêtre si loué l'été)",
                allowComment: true,
              },
            },
          },
          {
            id: "20",
            alias: "3-20",
            label: "20. Ventilation sanitaires",
            values: [],
            note: "Salle d'eau / bain et WC",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "3-20-1",
                label: "VMC",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Tolérance aération naturelle en 1 et 2 épis (simple grille ou extracteur / fenêtre si loué l'été)",
                allowComment: true,
              },
            },
          },
          {
            id: "21",
            alias: "3-21",
            label:
              "21. Chauffage fixe de qualité dans chaque pièce (19° minimum en efficacité)",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Tolérance point de chauffe unique si efficacité respectée\nRadiateurs mobiles tolérés jusqu'en 2 épis\nSauf DOM-TOM",
            allowComment: true,
          },
          {
            id: "22",
            alias: "3-22",
            label: "22. Efficacité production d'eau chaude",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Minimum 40L / personnes si système à accumulation (cumulus ou chauffe-eaux tous types)",
            allowComment: true,
          },
        ],
      },
      4: {
        id: "4",
        alias: "4",
        label: "IV. Équipement général",
        children: {
          23: {
            id: "23",
            alias: "4-23",
            label: "23. Connexion internet en WiFi",
            values: [
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Sauf impossibilité technique\nCouverture et débit suffisant - connexion filaire tolérée (CPL idéal / fournir câbles)\nConnexion possible et correcte a minima depuis la pièce de jour et la terrasse si immédiatement attenante",
            allowComment: true,
          },
          24: {
            id: "24",
            alias: "4-24",
            label:
              "24. Si hébergement situé en zone blanche, présence d'un téléphone fixe",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Ligne restreinte pour numéros d'urgence suffisante",
            allowComment: true,
          },
          25: {
            id: "25",
            alias: "4-25",
            label: "25. Lave-linge",
            values: [
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Équipement à remplacer si trop ancien\nLessive à fournir idéalement\n- Panier de linge sale conseillé (buanderie ou sdb privée)\n- Corbeille de linge conseillée si buanderie commune",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "4-25-1",
                label: "Lave-linge privatif",
                values: [
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Tolérance buanderie commune en 3 épis (avec autres gîtes ou propriétaire) / accès indépendant obligatoire\nMaximum 1 appareil pour 3 gîtes et / ou 12 personnes",
                allowComment: true,
              },
            },
          },
          26: {
            id: "26",
            alias: "4-26",
            label: "26. Sèche-linge électrique privatif",
            values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
            note: "Combiné lave-linge / sèche-linge toléré\nÉquipement à remplacer si trop ancien",
            allowComment: true,
          },
          27: {
            id: "27",
            alias: "4-27",
            label: "27. Fer + planche à repasser",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Équipement à remplacer si trop ancien",
            allowComment: true,
          },
          28: {
            id: "28",
            alias: "4-28",
            label:
              "28. Ustensiles de ménage appropriés au logement (dont aspirateur), produits de nettoyage de base et linge de table",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Balais en soie / nylon + balais de lavage à frange avec seau et presse + petite pelle et balayette + aspirateur\n- Maniques, sacs poubelles\nNettoyants multi-usage / produits éco-labellisés obligatoires / torchons, gant, ...",
            allowComment: true,
          },
          29: {
            id: "29",
            alias: "4-29",
            label: "29. Chaise + lit bébé (à fournir sur demande)",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Kit bébé à conseiller (+ baignoire, table à langer, chauffe biberon, ...) / matériel neuf, normes NF à privilégier\nAnciens lits à barreaux ou chaise en bois tolérés\nVeillez à la qualité du matelas",
            allowComment: true,
          },
          30: {
            id: "30",
            alias: "4-30",
            label: "30. Détecteur avertisseur autonome de fumée (DAAF)",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Installation conforme aux préconisations",
            allowComment: true,
          },
        },
      },
      5: {
        id: "5",
        alias: "5",
        label: "V. Aménagement / Équipement spécifique par pièce",
        children: [
          {
            id: "A",
            alias: "5-A",
            label: "A. CUISINE OU COIN CUISINE",
            values: [],
            note: "Parfait état exigé pour tous niveaux de classement\nRespect obligatoire niveau de standing (critères 67-68)",
            allowComment: true,
          },
          {
            id: "31",
            alias: "5-31",
            label: "31. Qualité de la cuisine",
            values: [],
            note: "Qualité-Harmonie mobilier-Matériaux :\n- Façades / évier crédence / hotte / plan de travail / robinets / ...\n- Ergonomie, praticité et fonctionnalité",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "5-31-1",
                label: "Cuisine simple",
                values: [HOTEL_RATING_EPI_VALUE_1],
                note: "Type : ancienne kitchenette monobloc basique avec simple évier inox + éléments disparates sans harmonie",
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "5-31-1",
                label: "Cuisine simple et fonctionnelle",
                values: [HOTEL_RATING_EPI_VALUE_2],
                note: "Type : cuisine intégrée bas de gamme ou simili (et / ou plus au goût du jour) avec plan de travail ou équivalent (table, desserte)\n- Harmonie du mobilier exigée",
                allowComment: true,
              },
              3: {
                id: "3",
                alias: "5-31-1",
                label: "Cuisine intégrée de qualité",
                values: [HOTEL_RATING_EPI_VALUE_3, HOTEL_RATING_EPI_VALUE_4],
                note: `Type : cuisine intégrée "déco" au goût du jour de la grande distribution (classique, traditionnelle, contemporaine)\nCuisine dispersée esprit "maison de campagne" tolérée (bloc évier + vaisselier et éléments séparés)`,
                allowComment: true,
              },
              4: {
                id: "4",
                alias: "5-31-1",
                label: "Cuisine intégrée haut de gamme",
                values: [HOTEL_RATING_EPI_VALUE_5],
                note: "Type : cuisine d'éditeurs / cuisinistes haut de gamme, design, luxe et caractère (classique, traditionnelle, contemporaine) ou historique (type château) ou création",
                allowComment: true,
              },
              5: {
                id: "5",
                alias: "5-31-2",
                label: "Équipements électroménagers de qualité",
                values: [
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Tolérance équipements simples 1 et 2 épis si bon état",
                allowComment: true,
              },
            },
          },
          {
            id: "32",
            alias: "5-32",
            label: "32. Robinetterie",
            values: [],
            note: "",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "5-32-1",
                label: "Robinets mitigeurs",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: 'Mélangeurs de style tolérés si parti-pris "déco"',
                allowComment: true,
              },
            },
          },
          {
            id: "33",
            alias: "5-33",
            label: "33. Table de cuisson 3 feux minimum",
            values: [],
            note: "Tolérance plaques 2 feux pour 3 personnes maxi (tous niveaux)\nTolérance plaques inductions avec zones modulables",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "5-33-1",
                label: "Plaques intégrées sur cuisinière",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                ],
                note: "Plaques électriques posables (toutes technologies) ou combiné four posable plaques électriques exclus",
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "5-33-1",
                label:
                  "Plaques encastrées sur plan de travail ou pianos de cuisson (gazinière type piano)",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "Vitrocéramiques ou induction / gaz / mixtes\nPlaques électriques basiques exclues (foyers fonte)",
                allowComment: true,
              },
            },
          },
          {
            id: "34",
            alias: "5-34",
            label: "34. Four",
            values: [],
            note: "",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "5-34-1",
                label:
                  "Cuisinière ou four posable haute technologie (chaleur tournante)",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                ],
                note: "Four posable 4 personnes maximum\nFour posable électriques à convection toléré en 1 et 2 épis\nTaille adaptée à la capacité et plats (diamètre / largeur)",
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "5-34-1",
                label:
                  "Four encastré haute technologie ou cuisinière haut de gamme type piano",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "Chaleur tournante (air brassé / air pulsé), induction vapeur, ...",
                allowComment: true,
              },
              3: {
                id: "3",
                alias: "5-34-2",
                label: "Four micro-ondes",
                values: [
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Combiné four / four micro-onde toléré jusqu'en 3 épis",
                allowComment: true,
              },
            },
          },
          {
            id: "35",
            alias: "5-35",
            label: "35. Réfrigérateur",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Base 140L pour 4 personnes + 15L / personnes supplémentaires\nTolérance table-top 100L pour 2 personnes et 3 épis maximum",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "5-35-1",
                label: "Compartiment congélateur ou congélateur",
                values: [
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Accès direct et aisé si congélateur indépendant\nRappel : un conservateur (pictogramme 3 étoiles) n'est pas un congélateur (pictogramme 4 étoiles)\nConseillé dès 2 épis",
                allowComment: true,
              },
            },
          },
          {
            id: "36",
            alias: "5-36",
            label: "36. Lave-vaisselle",
            values: [
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Volume adapté à la capacité d'accueil\n- Minimum 6 couverts tolérés pour 2 personnes et 3 épis maximum\n- Compact 10 couverts (45cm) tolérés pour 4 personnes maximum\nTolérance si impossibilité technique et capacité 2 personnes en 3 épis",
            allowComment: true,
          },
          {
            id: "37",
            alias: "5-37",
            label: "37. Petit électroménager",
            values: [],
            note: 'Parfait état exigé (fonctionnement = esthétique) "ancien" matériel toléré en 1 et 2 épis uniquement\nÉquipements actuels au goût du jour exigés dès 3 épis',
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "5-37-1",
                label: "Cafetière électrique à filtre",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Cafetière traditionnelle ne compense pas l'équipement (piston ou dépression / manuelle à filtres / italienne, ...)",
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "5-37-2",
                label: "Cafetière à dosettes",
                values: [
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Machine expresso classique (dont combiné compense l'équipement",
                allowComment: true,
              },
              3: {
                id: "3",
                alias: "5-37-3",
                label: "Mixer électrique + grille pain + bouilloire électrique",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: 'Un thermoplongeur ou une bouilloire manuelle ne compensent pas une bouilloire électrique\nA minima pied mixer / "blender" à conseiller',
                allowComment: true,
              },
              4: {
                id: "4",
                alias: "5-37-4",
                label: "Robot multifonctions",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "",
                allowComment: true,
              },
            },
          },
          {
            id: "38",
            alias: "5-38",
            label:
              "38. Batterie de cuisine complète et vaisselle non dépareillées, adaptées à la capacité d'accueil",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Parfait état exigé (liste a minima / non exhaustif)\n- Casseroles et poêles différents diamiètre-contenance\n- Sauteuse + faitout et / ou marmite + poêle crêpière\n- Couvercles adaptés\nAnciennes casseroles en aluminium exclues\n- Assiettes plates (x 2) + creuses + dessert\n- Verres ordinaires (x 2) + verres à vin + verres à apéritif\n- Couverts complets (dont couteaux à viande / x 2)\n- À conseiller : flutes champagne, coupe glace, ramequin",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "5-38-1",
                label: "Batterie de cuisine et vaisselle de qualité supérieure",
                values: [
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Inox / acier / fonte / revêtement céramique ou pierre, ...\nVaisselle type : qualité hôtelière, traditionnelle, artisanale haut de gamme, ...",
                allowComment: true,
              },
            },
          },
          {
            id: "39",
            alias: "5-39",
            label:
              "39. Autocuiseur ou cuiseur-vapeur ou multi-cuiseur ou faitout",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Adapté à la capacité d'accueil\nPanier obligatoire pour autocuiseurs",
            allowComment: true,
          },
          {
            id: "B",
            alias: "5-B",
            label: "B. SÉJOUR / SALON",
            values: [],
            note: "",
            allowComment: true,
          },
          {
            id: "40",
            alias: "5-40",
            label: "40. Qualité et confort de l'espace repas",
            values: [],
            note: "Ergonomie-praticité dont circulation et facilité d'accès + dimensionnement + qualité-harmonie du mobilier\nRespect obligatoire du niveau de standing",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "5-40-1",
                label: "Adapté à la capacité d'accueil et fonctionnel",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Nombre d'assises supérieur à la capacité conseillé table modulable à rallonge tolérée (cf grand capacité)",
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "5-40-2",
                label: "Simple",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                note: 'Galettes de chaises "déco" à conseiller si besoin\nPatins de chaises conseillés si besoin (nuisance sonore)',
                allowComment: true,
              },
              3: {
                id: "3",
                alias: "5-40-2",
                label: "De qualité et bien agencé",
                values: [HOTEL_RATING_EPI_VALUE_3],
                note: "Blancs et tabourets à déconseiller\nTable-bar avec tabourets-chaise de bar à déconseiller",
                allowComment: true,
              },
              4: {
                id: "4",
                alias: "5-40-2",
                label: "De qualité et bien agencé",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "Haut de gamme / table-bar avec tabourets-chaises de bar exclus (sauf studio ou capacité d'accueil réduite)\ntabourets exclus / bancs toléré si parti pris déco réussi",
                allowComment: true,
              },
              5: {
                id: "5",
                alias: "5-40-3",
                label: "Éclairage de qualité",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Idéalement point(s) lumineux surplombant la table\nÉclairage suffisant et adapté sans éblouissement",
                allowComment: true,
              },
            },
          },
          {
            id: "41",
            alias: "5-41",
            label: "41. Qualité et confort de l'espace détente / salon",
            values: [],
            note: `Ergonomie-praticité dont circulation-cheminement et facilité d'accès + dimensionnement et qualité-harmonie du mobilier (style et matière)\nMobilier "outdoor" non toléré\nRespect obligatoire du niveau de standing`,
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "5-41-1",
                label: "Simple espace détente",
                values: [HOTEL_RATING_EPI_VALUE_2],
                note: "Tolérance d'un simple appoint / aucune obligation d'adaptation stricte à la capacité d'accueil / tolérance simple sièges de détente ou banquette (ou assimilés)",
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "5-41-1",
                label:
                  "Espace salon dédié confortable de qualité avec table basse, adapté à la capacité d'accueil",
                values: [HOTEL_RATING_EPI_VALUE_3],
                note: `Salon ou espace salon spécifiquement dédié et aménagé\nCanapés "déco" esprit " sofa " (avec joues/accoudoirs) et fauteuils " déco " de série ou anciens de qualité\nClic-clac / BZ / futon / chauffeuses de base non tolérés (+ toute assisse basique = tabourets, bancs, siège, ...)\nPoufs déconseillés (poires ou cubes) / tapis conseillé`,
                allowComment: true,
              },
              3: {
                id: "3",
                alias: "5-41-1",
                label:
                  "Espace salon dédié de grand confort et grande qualité avec table basse, adapté à la capacité d'accueil",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "Haut de gamme (dimensions-profondeur-densité supérieures) de style, caractère, design, historique (antiquaire, de collection, d’exception, ...)\nSouvent accessoirisés (coussins, peaux, petits plaids, ...)\nTapis de grande qualité conseillé\nPoufs ou assimilés exclus sauf appoint de complément",
                allowComment: true,
              },
            },
          },
          {
            id: "42",
            alias: "5-42",
            label: "42. Éclairage d'ambiance supplémentaire",
            values: [
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Lampadaires / lampes sur pied / barres de LEDs / spots encastrés orientables ou non / appliques / ...\nLampadaires halogènes à déconseiller",
            allowComment: true,
          },
          {
            id: "43",
            alias: "5-43",
            label: "43. TV écran plat 80cm minimum (avec télécommande)",
            values: [
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Cran adapté à la superficie de la pièce et la capacité ultra HD 4K ou OLED conseillé dès 3 épis\nTV cathodique exclue pour tout nouvel agrément câble de connexion pour PC à conseiller (HDMI, ...)",
            allowComment: true,
            children: [
              {
                id: "1",
                alias: "5-43-1",
                label: "Accès à des chaines européennes et étrangères",
                values: [HOTEL_RATING_EPI_VALUE_5],
                note: "Une TV connectée avec accès direct aux plateformes de vidéos en streaming valide le critère",
                allowComment: true,
              },
            ],
          },
          {
            id: "44",
            alias: "5-44",
            label: "44. Équipement HiFi connecté",
            values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
            note: "Chaines avec fonction Bluetooth ou Wifi (ou port USB)\nColonne-tour de son avec fonction Bluetooth ou Wifi stations d’accueil Hifi / une chaine Hifi standard valide le critère (adaptateurs-récepteurs Bluetooth possibles)",
            allowComment: false,
          },
          {
            id: "C",
            alias: "5-C",
            label: "C. CHAMBRES",
            values: [],
            note: "",
            allowComment: true,
          },
          {
            id: "45",
            alias: "5-45",
            label: "45. Dimension de la literie",
            values: [],
            note: `Lits superposés et lits gigognes tolérés jusqu’en 3 épis\nConvertibles en couchage permanent exclus dès 3 épis\nLits mezzanine exclus dès 4 épis (sauf chambre enfants)\nLits escamotables (rabattables ou " de plafond ") et lits d’appoint (" pliants ") non tolérés`,
            allowComment: true,
            children: [
              {
                id: "1",
                alias: "5-45-1",
                label: "Lits 1 personnes en 90x190cm",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                ],
                note: "Lits en 80cm tolérés dans le cas de lits jumelables y compris en 4 épis",
                allowComment: true,
              },
              {
                id: "2",
                alias: "5-45-1",
                label: "Lits 1 personnes en 90x200cm",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "À conseiller dans l'absolu (niveau de confort standard)",
                allowComment: true,
              },
              {
                id: "3",
                alias: "5-45-2",
                label: "Lits 2 personnes en 140x190cm",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                ],
                note: "",
                allowComment: true,
              },
              {
                id: "4",
                alias: "5-45-2",
                label: "Lits 2 personnes en 160x200cm",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "À conseiller dans l'absolu (niveau de confort standard)",
                allowComment: true,
              },
            ],
          },
          {
            id: "46",
            alias: "5-46",
            label: "46. Qualité de la literie",
            values: [],
            note: 'Confortable et parfait état exigés tous classements\nSommiers métalliques et avec "planches" exclus\nSommiers standard à ressorts déconseillés\nMatelas en laine, en mousse polyester, à ressorts Coniques ou multispires et à eau déconseillés\n! Accord matelas/sommier majeur',
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "5-46-1",
                label: "Sommier et matelas de qualité",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                note: "Type : sommiers à lattes passives\nMatelas mousse haute résilience 40kg/m³ (mini)",
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "5-46-1",
                label: "Sommier et matelas de très bonne qualité",
                values: [HOTEL_RATING_EPI_VALUE_3],
                note: "Type : tous sommiers à lattes actives (tapissiers ou non)\nMatelas mousse latex 60kg/m³ (mini)",
                allowComment: true,
              },
              3: {
                id: "3",
                alias: "5-46-1",
                label: "Sommier et matelas haut de gamme de grande qualité",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: 'Type : sommiers tapissiers à ressorts ensachés ou à plots ou à lattes avec suspension et curseurs de fermeté\nMatelas en mousse " mémoire de forme ", matelas en latex ou matelas à ressorts ensachés 70kg/m³ (mini)\nSurmatelas à conseiller si besoin (amélioration accueil)',
                allowComment: true,
              },
              4: {
                id: "4",
                alias: "5-46-2",
                label: "Protection par alèses ou housses + protège-oreillers",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "",
                allowComment: true,
              },
              5: {
                id: "5",
                alias: "5-46-3",
                label: "1 couette pour chaque lit",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: 'Couvertures tolérées de 1 à 2 épis (2 par couchage)\nCouvertures polaires conseillées (ou de parfaite qualité)\nAdaptée à la saison et-ou au climat ou " 4 saisons "\nTrès bonne qualité en 3 épis / grande qualité dès 4 épis\nCourtepointe de qualité valide le critère (si location été)',
                allowComment: true,
              },
              6: {
                id: "6",
                alias: "5-46-4",
                label: "1 oreiller par personne",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                note: "Ou 1 traversin double si lit 2 personnes (mais déconseillé)",
                allowComment: true,
              },
              7: {
                id: "7",
                alias: "5-46-4",
                label: "2 oreillers par personne",
                values: [
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Bonne densité exigée (à remplacer sinon)\nTrès bonne qualité en 3 épis / grande qualité dès 4 épis",
                allowComment: true,
              },
              8: {
                id: "8",
                alias: "5-46-5",
                label: "Linge de lit de qualité",
                values: [HOTEL_RATING_EPI_VALUE_2],
                note: 'Parure : drap housse + taies oreillers + housse couette\nType : parure de qualité standard (polycoton)\nCouvre-lit kitch "vintage" années 60-80 (moumoute Synthétique, coton à franges, tuft, satin matelassé...) ou " fait maison " (dentelles, crochet...) non tolérés',
                allowComment: true,
              },
              9: {
                id: "9",
                alias: "5-46-5",
                label: "Linge de lit de très bonne qualité",
                values: [HOTEL_RATING_EPI_VALUE_3],
                note: `Type : parure de qualité supérieure au standard (100% coton, lin, métis, jersey...)\nEsprit " déco " soigné (harmonie)`,
                allowComment: true,
              },
              10: {
                id: "10",
                alias: "5-46-5",
                label: "Linge de lit de grande qualité",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: `Type : parure de grande qualité, haut de gamme (percale, satin, flanelle, étamine en coton et/ou lin...)\nLit accessoirisé dans un esprit " déco " recherché : assortiment de coussins, édredon, plaids, jetée de lit, ...`,
                allowComment: true,
              },
            },
          },
          {
            id: "47",
            alias: "5-47",
            label: "47. Élements de mobilier",
            values: [],
            note: "Parfait état et harmonie exigés pour tous niveaux de classement\nRespect obligatoire du niveau de standing",
            allowComment: true,
            children: [
              {
                id: "1",
                alias: "5-47-1",
                label: "Table de chevet pour chaque couchage",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Meuble, élément mural intégré à la tête de lit, niche, ...\nUn chevet commun entre deux lits valide le critère",
                allowComment: true,
              },
              {
                id: "2",
                alias: "5-47-2",
                label: "Tête de lit",
                values: [
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: `Absence de tête de lit tolérée si bonne intégration "déco" selon qualité gros œuvre (revêtement mural) Tringle basique avec coussin déconseillée (exclus dès 4)\n"Desserte" de pied de lit à déconseiller et supprimer si possible (confort d'usage du lit)`,
                allowComment: true,
              },
              {
                id: "3",
                alias: "5-47-3",
                label: "Rangement adapté à la capacité d'accueil",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: `Exiger idéalement des cintres en bois de qualité\nAnciens cintres métalliques à supprimer\nPenderies ouvertes ou à rideaux tolérées de 1 à 3 épis\nSimple penderie type " portant " sur roulettes tolérée de 1 à 2 épis\nConseiller rajout porte-valises " déco " ou bout de lit (meuble) à partir de 3 épis`,
                allowComment: true,
              },
              {
                id: "4",
                alias: "5-47-4",
                label: "Miroir en pied",
                values: [
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: 'A minima un par gîte\nUne porte "vitrée" toute hauteur valide le critère\nPeut être fixé au mur',
                allowComment: true,
              },
            ],
          },
          {
            id: "48",
            alias: "5-48",
            label: "48. Éclairage",
            values: [],
            note: "",
            allowComment: true,
            children: [
              {
                id: "1",
                alias: "48_1",
                label: "Lumière de chevet pour chaque couchage",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Lampe de chevet ou appliques ou liseuses",
                allowComment: true,
              },
            ],
          },
          {
            id: "D",
            alias: "5-D",
            label: "D. SALLE(S) D'EAU / BAIN / WC",
            values: [],
            note: "Parfait état exigé tous niveaux de classement\nRespect obligatoire niveau de standing",
            allowComment: true,
          },
          {
            id: "49",
            alias: "5-49",
            label: "49. Qualité des matériaux et équipements sanitaires",
            values: [],
            note: "Qualité du mobilier-matériaux : revêtements de sol et muraux, type de douche et / ou baignoire, lavabo, vasques, robinetterie, mobilier et accessoires, miroir, ...\nDimensions, ergonomie, praticité et fonctionnalité",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "5-49-1",
                label: "Bonne qualité",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                note: 'Type : équipements-matériaux simples actuels ou 70-90\n- Faïence ancienne, peinture basique, lambris bois-PVC, toile de verre texturée, sol plastique, ... / simple lavabo - colonne, ancien bidet, armoire toilette "vintage", ...\n- Tolérance cabine de douche monobloc basique dont 70x70cm (si impossibilité technique) et baignoire sabot\n- Rideau de douche toléré si parfait état (à déconseiller)',
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "5-49-1",
                label: "Très bonne qualité",
                values: [HOTEL_RATING_EPI_VALUE_3],
                note: `Type : équipements et matériaux de qualité supérieure au goût du jour (quel que soit le style)\n- Cabines de douches avec porte de qualité supérieure (90x90 minimum) ou sur-mesure (douche à l'italienne) baignoire standard de qualité (170x170cm minimum)\n- Tolérance douche 80x80cm si candidature spontanée\n- Meuble plan vasque ou lavabo colonne "deco"`,
                allowComment: true,
              },
              3: {
                id: "3",
                alias: "5-49-1",
                label: "Grande qualité, haut de gamme",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: `Type : équipements et matériaux haut de gamme\n- Faïençage toute hauteur, mosaïque pâte de verre, béton ciré, pierres, résine, verre-métal, " Solid Surface "\n- Douche maçonnée sur mesure (" à l’italienne ") ou cabine de douche design/hi-tech grandes dimensions... baignoire îlot, balnéo, d’angle de tous styles (design, classique, " rétro ", ...) haut de gamme (180x80cm mini)\n- Meuble (double) vasque ou lavabo-colonne haut de gamme (" totem " design, classique, " rétro ", ...)`,
                allowComment: true,
              },
            },
          },
          {
            id: "50",
            alias: "5-50",
            label:
              "50. WC avec chasse d'eau double débit + lunette et abattant",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Support-papier et brosse de toilette obligatoire\nAbattants silencieux à conseiller (avec frein de chute)",
            allowComment: true,
          },
          {
            id: "51",
            alias: "5-51",
            label: "51. Robinetterie",
            values: [],
            note: "Parfait état exigé tous niveaux de classement\nFlexible de douche en parfait état",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "5-51-1",
                label: "Robinets mitigeurs",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: 'Mélangeurs de style tolérés si parti-pris "deco"',
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "5-51-2",
                label: "Mitigeurs thermostatiques (douche et / ou baignoires)",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "Adaptations possible pour candidatures spontanées",
                allowComment: true,
              },
              3: {
                id: "3",
                alias: "5-51-3",
                label: "Douchette au niveau de la baignoire",
                values: [
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "",
                allowComment: true,
              },
            },
          },
          {
            id: "52",
            alias: "5-52",
            label: "52. Rangements adaptés à la capacité d'accueil",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "A minima tablette large et fonctionnelle de 1 à 2 épis\nDessertes à roulettes basiques tolérées de 1 à 2 épis\nArmoire de toilette basique années 70-90 (plastique ou métal) à remplacer idéalement (1 et 2 épis)",
            allowComment: true,
          },
          {
            id: "53",
            alias: "5-53",
            label: "53. Miroir avec éclairage adapté de qualité",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Taille du miroir adaptée à la surface de la pièce\nMiroir à remplacer si usé et piqué\nVérifier conformité de la hauteur (≈ 1.70m au centre)\nÉclairage obligatoire : latéral (idéal) ou au-dessus\nSimple tube néon basique non toléré dès 3 épis",
            allowComment: true,
          },
          {
            id: "54",
            alias: "5-54",
            label: "54. Prise électrique libre (pour rasoir et sèche-cheveux)",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Conforme aux normes de sécurité",
            allowComment: true,
          },
          {
            id: "55",
            alias: "5-55",
            label:
              "55. Patères + porte-serviettes + tapis de bain + petite poubelle",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Accessoires basiques années 70-90 (plastique ou métal) à remplacer idéalement (1 et 2 épis)\nEssuie-main tissu (coton / éponge) / sèche-main ou distributeur type hôtelier déconseillés / Essuie-tout exclus\nPanier à linge à conseiller si nécessaire",
            allowComment: true,
          },
          {
            id: "56",
            alias: "5-56",
            label: "56. Sèche-serviettes chauffant",
            values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
            note: "Adaptations possibles pour candidatures spontanées\nConseillé dès 3 épis",
            allowComment: true,
          },
          {
            id: "57",
            alias: "5-57",
            label: "57. Sèche-cheveux électrique",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Équipement à remplacer si trop ancien\n1 sèche-cheveux pour 2 salles d'eau minimum",
            allowComment: true,
          },
        ],
      },
      6: {
        id: "6",
        alias: "6",
        label: "VI. Services",
        children: [
          {
            id: "58",
            alias: "6-58",
            label: "58. Jeux de société",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "A minima : jeu traditionnel de 54 cartes + jeu de tarot de 78 cartes\nIdéalement mallette de jeux traditionnels ou jeux standards et populaires (Trivial Pursuit, Monopoly, Uno, ...) / jeux simples suffisent (Mikado, Dés avec tapis de jeu, ...)",
            allowComment: true,
          },
          {
            id: "59",
            alias: "6-59",
            label:
              "59. Magazines, guides et livres touristiques, documents d'information touristiques (dépliants, brochures, ...)",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Obligatoirement en bon état\nDocumentation touristiques obligatoirement à jour\nSupport numériques acceptés (téléchargeables via QR Code par exemple, ...)",
            allowComment: true,
            children: [
              {
                id: "1",
                alias: "6-59-1",
                label: "Coin bibliothèque avec livres de qualité",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "Beaux ouvrages régionaux (ou autres) + romans, BDs, essais, livres pour enfants, magazines de qualité supérieure (papier glacé, ...)",
                allowComment: true,
              },
            ],
          },
          {
            id: "60",
            alias: "6-60",
            label: "Qualité du livret d'accueil",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Livret officiel de Gîtes de France idéalement / infos à jour\nÀ remplacer si trop ancien",
            allowComment: true,
          },
          {
            id: "61",
            alias: "6-61",
            label: "Draps + linge de bain payant à la demande",
            values: [HOTEL_RATING_EPI_VALUE_2, HOTEL_RATING_EPI_VALUE_3],
            note: "Grammage de 500gr-m² minimum conseillé pour le linge de bain / linge de bain et de lit publicitaires exclu",
            allowComment: true,
          },
          {
            id: "62",
            alias: "6-62",
            label: "Draps + lits faits + linge de bain",
            values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
            note: "1 drap de bain + 1 serviette de toilette par personne\nMinimum : shampooing, savon, mouchoirs / distributeurs conseillés (conditionnements individuels à éviter) produits écologiques conseillés",
            allowComment: true,
          },
          {
            id: "63",
            alias: "6-63",
            label: "Produits d'accueil cosmétiques et d'hygiène tout inclus",
            values: [HOTEL_RATING_EPI_VALUE_5],
            note: "",
            allowComment: true,
          },
          {
            id: "64",
            alias: "6-64",
            label: "Serviettes de plage incluses si piscine",
            values: [HOTEL_RATING_EPI_VALUE_5],
            note: "Peignoirs et chaussons de bain jetables à conseiller le cas échéant / serviettes publicitaires-fantaisie exclues\nÀ conseiller cas échéant si toute proximité de la plage",
            allowComment: true,
          },
          {
            id: "65",
            alias: "6-65",
            label: "Service de ménage payant à la demande",
            values: [
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
            ],
            note: "",
            allowComment: true,
          },
          {
            id: "66",
            alias: "6-66",
            label: "Service de ménage inclus",
            values: [HOTEL_RATING_EPI_VALUE_5],
            note: "",
            allowComment: true,
          },
        ],
      },
      7: {
        id: "7",
        alias: "7",
        label: "VII. Standing / Cachet / Décoration",
        children: [
          {
            id: "67",
            alias: "7-67",
            label:
              "Qualité des matériaux, du second oeuvre et des finitions (style et harmonie d'ensemble)",
            values: [],
            note: "Parfait état exigé pour tous niveaux de classement\n- Revêtements des sols, murs, plafonds, ...\n- Portes, portes de placards, huisseries, escaliers, ...\n- Poignées portes, plinthes, baguettes, interrupteurs, ...\n- Radiateurs, équipements sanitaires, hotte, ...",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "7-67-1",
                label: "Bonne qualité",
                values: [HOTEL_RATING_EPI_VALUE_1, HOTEL_RATING_EPI_VALUE_2],
                note: "",
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "7-67-1",
                label: "Très bonne qualité",
                values: [HOTEL_RATING_EPI_VALUE_3],
                note: "",
                allowComment: true,
              },
              3: {
                id: "3",
                alias: "7-67-1",
                label: "Grande qualité, haut de gamme",
                values: [HOTEL_RATING_EPI_VALUE_4, HOTEL_RATING_EPI_VALUE_5],
                note: "",
                allowComment: true,
              },
            },
          },
          {
            id: "68",
            alias: "7-68",
            label:
              "68. Qualité du mobilier et de la décoration (style et harmonie d'ensemble)",
            values: [],
            note: "Parfait état exigé pour tous niveaux de classement\nMobilier + accessoires (luminaires, coussins, rideaux, tapis, tableaux, vases, objets divers, ...)",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "7-68-1",
                label: "Harmonie d'ensemble obligatoire",
                values: [
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Souplesse et tolérance possible en 2 épis",
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "7-68-2",
                label: "Mobilier et décoration basiques",
                values: [HOTEL_RATING_EPI_VALUE_1],
                note: "Strict minimum tolérable / absence d'harmonie tolérée uniquement fonctionnel / aucune décoration tolérée",
                allowComment: true,
              },
              3: {
                id: "3",
                alias: "7-68-2",
                label: "Mobilier et décoration simples",
                values: [HOTEL_RATING_EPI_VALUE_2],
                note: "Sans caractère particulier (style) mais harmonieux ancien mobilier plus au goût du jour + peu de décoration tolérés",
                allowComment: true,
              },
              4: {
                id: "4",
                alias: "7-68-2",
                label: "Mobilier de qualité et décoration soignée",
                values: [HOTEL_RATING_EPI_VALUE_3],
                note: 'Mobilier au goût du jour quel que soit le style\nBasculement en esprit "deco" (ambiance cosy)',
                allowComment: true,
              },
              5: {
                id: "5",
                alias: "7-68-2",
                label: "Mobilier de grande qualité et décoration recherchée",
                values: [HOTEL_RATING_EPI_VALUE_4],
                note: "Demeure de charme de grand standing à tous niveaux\nIntérieur digne d'un magazine de décoration",
                allowComment: true,
              },
              6: {
                id: "6",
                alias: "7-68-2",
                label: "Mobilier très haut de gamme et décoration raffinée",
                values: [HOTEL_RATING_EPI_VALUE_5],
                note: "Exceptionnel, rare, ultra haut de gamme (prestige, luxe)",
                allowComment: true,
              },
            },
          },
        ],
      },
      8: {
        id: "8",
        alias: "8",
        label: "VIII. Développement durable",
        children: [
          {
            id: "69",
            alias: "8-69",
            label: "69. Écogestes",
            values: [
              HOTEL_RATING_EPI_VALUE_1,
              HOTEL_RATING_EPI_VALUE_2,
              HOTEL_RATING_EPI_VALUE_3,
              HOTEL_RATING_EPI_VALUE_4,
              HOTEL_RATING_EPI_VALUE_5,
            ],
            note: "Points obligatoires pour toutes les catégories de classement",
            allowComment: true,
            children: {
              1: {
                id: "1",
                alias: "8-69-1",
                label:
                  "100% des ampoules sont de classe énergétique A, A+ ou de technologies LED",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "",
                allowComment: true,
              },
              2: {
                id: "2",
                alias: "8-69-2",
                label:
                  "Pour tout achat de nouvel équipement, choisir une étiquette énergie classe B a minima",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Selon nouvelle réglementation (mars 2021)",
                allowComment: true,
              },
              3: {
                id: "3",
                alias: "8-69-3",
                label:
                  "Réducteurs d'eau et / ou mousseurs sur tous les robinets (sauf arrosage extérieur)",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Délai possible pour installation jusqu'au prochain reclassement",
                allowComment: true,
              },
              4: {
                id: "4",
                alias: "8-69-4",
                label:
                  "WC équipés d'un mécanisme de chasse à double poussoir ou d'une chasse d'eau inférieure à 6L",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Délai possible pour installation jusqu'au prochain reclassement",
                allowComment: true,
              },
              5: {
                id: "5",
                alias: "8-69-5",
                label:
                  "Produits d'entretien fournis biodégradables et éco-labellisés",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "",
                allowComment: true,
              },
              6: {
                id: "6",
                alias: "8-69-6",
                label:
                  "Mettre à disposition une plaquette propre à la structure pour informer vos hôtes de vos engagements en faveur du tourisme durable",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Modèle pouvant être fourni par le Relais Départemental",
                allowComment: true,
              },
              7: {
                id: "7",
                alias: "8-69-7",
                label:
                  "Mettre à disposition des touristes de la documentation sur des sites et prestataires ayant une thématique de développement durable",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Ou circuits courts",
                allowComment: true,
              },
              8: {
                id: "8",
                alias: "8-69-8",
                label: "Poubelle fermée avec tri sélectif",
                values: [
                  HOTEL_RATING_EPI_VALUE_1,
                  HOTEL_RATING_EPI_VALUE_2,
                  HOTEL_RATING_EPI_VALUE_3,
                  HOTEL_RATING_EPI_VALUE_4,
                  HOTEL_RATING_EPI_VALUE_5,
                ],
                note: "Sauf impossibilité (tri non organisé par la commune)\nFourniture des sacs + info (règles + points de collecte)",
                allowComment: true,
              },
              9: {
                id: "9",
                alias: "8-69-9",
                label:
                  "Autres points relatifs aux écogestes pratiques (par ex : compost, régulation chauffage, borne de recharge pour véhicules électriques, ...)",
                values: [],
                note: "",
                allowComment: true,
              },
            },
          },
        ],
      },
    },
  },
};
