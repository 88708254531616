// deps
import { defineMessage } from "react-intl";

// Catégorie de notation

/** Epi */
export const ROOM_CATEGORY_RANKING_VALUE_EPI = "epi";
/** Citybreak */
export const ROOM_CATEGORY_RANKING_VALUE_CITYBREAK = "citybreak";
/** Aucun */
export const ROOM_CATEGORY_RANKING_VALUE_NONE = "none";

export const ROOM_CATEGORY_RANKINGS = {
  [ROOM_CATEGORY_RANKING_VALUE_EPI]: {
    id: ROOM_CATEGORY_RANKING_VALUE_EPI,
    label: "épi",
  },
  [ROOM_CATEGORY_RANKING_VALUE_CITYBREAK]: {
    id: ROOM_CATEGORY_RANKING_VALUE_CITYBREAK,
    label: "citybreak",
  },
  [ROOM_CATEGORY_RANKING_VALUE_NONE]: {
    id: ROOM_CATEGORY_RANKING_VALUE_NONE,
  },
};

export const ROOM_CATEGORY_INTL_MESSAGES = {
  byValue: {
    [ROOM_CATEGORY_RANKING_VALUE_EPI]: {
      label: defineMessage({
        defaultMessage: "épi",
      }),
      labelWithCount: defineMessage({
        defaultMessage:
          "{realmReference, select, GDF {{epiCount, plural, one {# épi} other {# épis}}} CLEV {{epiCount, plural, one {# clé} other {# clés}}} other {}}",
      }),
    },
    [ROOM_CATEGORY_RANKING_VALUE_CITYBREAK]: {
      label: defineMessage({
        defaultMessage: "citybreak",
      }),
    },
    [ROOM_CATEGORY_RANKING_VALUE_NONE]: {
      label: defineMessage({
        defaultMessage: "aucun",
      }),
    },
  },
};

export const ROOM_CATEGORY_RANKING_LIST = Object.values(ROOM_CATEGORY_RANKINGS);
